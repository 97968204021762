<template>
  <div class="main-wrapper" style="background-color: #fff;">

    <div class="search-bar">
      <input type="search" name="" id="" placeholder="Search" v-model="search" @change="getInstitution">
      <div class="d-flex">
        <!-- <button class="mr-2" @click="notificationDialog = true"><font-awesome-icon icon="fa-solid fa-bell" size="lg"/></button> -->
        <button @click="dialog = true" class="mr-2">Create Institution</button>
        <button @click="campusDialog = true" class="mr-2">Create Campus</button>
        <button @click="adminDialog = true">Create Admin</button>
      </div>
    </div>
    <div class="complaint-table">
      <v-table class="table">
        <thead class="table-header__color">
          <tr>
            <th class="text-left text-uppercase font-weight-bold">
              S/N
            </th>
            <th class="text-left text-uppercase font-weight-bold">
              Name
            </th>
            <th class="text-left text-uppercase font-weight-bold">
              Code
            </th>
            <th class="text-center text-uppercase font-weight-bold">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && institutions.length < 1" class="text-center">
            <td colspan="4">Loading...</td>
          </tr>
          <tr v-if="!loading && institutions.length < 1" class="text-center">
            <td colspan="4">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(institution, index) in institutions" :key="index">
            <td class="text-left">{{ index + 1 }}</td>
            <td class="text-left">{{ institution.name }}</td>
            <td class="text-left">{{ institution.code }}</td>
            <td class="text-center">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <font-awesome-icon icon="fa-solid fa-ellipsis" v-bind="props" size="xl" class="" />

                </template>

                <v-list>
                  <v-list-item class="list-item-btn">
                    <!-- <v-list-item-title> -->
                    <button class="btn  btn-info" @click="handleEdit(institution)">Edit

                    </button>

                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-info " @click="getCampus(institution.id)">View Campus

                    </button>
                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-info" @click="getAdmin(institution.id)">View Admin

                    </button>
                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-primary" v-if="institution.active == false"
                      @click="activate(institution.id, 1)">Activate

                    </button>
                    <button class="btn btn-danger btn-md" v-else
                      @click="activate(institution.id, 0)">Deactivate

                    </button>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <div class="btn-menu-lg">
                <button class="btn-submit btn-info mr-2" @click="handleEdit(institution)">Edit

                </button>
                <button class="btn-submit btn-info mr-2" @click="getCampus(institution.id)">View Campus

                </button>
                <button class="btn-submit btn-info mr-2" @click="getAdmin(institution.id)">View Admin

                </button>

                <button class="btn-submit btn-primary mr-2" v-if="institution.active == false"
                  @click="activate(institution.id, 1)">Activate

                </button>
                <button class="btn-submit btn-danger mr-2" v-else @click="activate(institution.id, 0)">Deactivate

                </button>
              </div> -->
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
    <v-row justify="space-around" v-if="dialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent class="dialog">
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Institution' : 'Add a New Institution'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="Name" variant="outlined" v-model="formData.name" :rules="rules"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="dialog = false; clearForm()">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" :disabled="!form"
                @click="editInstitution">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click.prevent="createInstitution"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="campusDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="campusDialog" class="dialog" persistent>
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Campus' : 'Add a New Campus'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="Name" variant="outlined" v-model="formData.name" :rules="rules"></v-text-field>
                <v-select v-if="!edit" label="Select" :item-title="'name'" :item-value="'id'" :items="institutions"
                  variant="outlined" v-model="formData.institution_id" :rules="[requiredRules.required]" clearable></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="campusDialog = false; clearForm();">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" @click="editCampus"
                :disabled="!form">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click="createCampus"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="campusListDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-top-transition" v-model="campusListDialog" class="dialog" persistent>
          <v-card>
            <v-toolbar color="#10428D" title="List of Campuses"></v-toolbar>
            <div class="store-table shadow">

              <v-table class="table" hover="true">
                <thead>
                  <tr>
                    <th class="text-left text-uppercase font-weight-bold">
                      S/N
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Name
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading && campus.length < 1" class="text-center">
                    <td colspan="3">Loading...</td>
                  </tr>
                  <tr v-if="!loading && campus.length < 1" class="text-center">
                    <td colspan="3">No Record at the Moment</td>
                  </tr>
                  <tr v-else v-for="(x, index) in campus" :key="index">
                    <td>{{ index + 1
                    }}</td>
                    <td class="text-left">{{ x.name }}</td>
                    <td class="text-center table-actions">
                      <button class="btn btn-info btn-md" @click="handleCampusEdit(x)">Edit</button>
                      <!-- <button class="btn btn-primary mr-2" v-if="x.active == false"
                        @click="activateCampus(1)">Activate</button>
                      <button class="btn btn-danger mr-2" v-else @click="activateCampus(0)">Deactivate</button> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions class="justify-end">
                <v-btn variant="tonal" @click="campusListDialog = false">Close</v-btn>
              </v-card-actions>
            </div>
          </v-card>

        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around" v-if="adminDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="adminDialog" class="dialog" persistent>
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Admin' : 'Add a New Admin'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="First Name" variant="outlined" v-model="adminData.first_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Last Name" variant="outlined" v-model="adminData.last_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Email" variant="outlined" v-model="adminData.email" type="email"
                  :rules="emailRules"></v-text-field>
                <v-text-field label="Phone" variant="outlined" v-model="adminData.phone" type="tel"
                  :rules="PhoneRules"></v-text-field>
                <v-select label="Select" :item-title="'name'" :item-value="'id'" :items="institutions" variant="outlined"
                  v-model="adminData.institution_id" :rules="[requiredRules.required]" clearable></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="adminDialog = false; clearAdminForm()">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" @click="editAdmin"
                :disabled="!form">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click="createAdmin"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="adminListDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-top-transition" v-model="adminListDialog" class="admin-dialog" persistent>
          <v-card>
            <v-toolbar color="#10428D" title="List of Admin"></v-toolbar>
            <div class="store-table shadow">

              <v-table class="table" hover="true">
                <thead>
                  <tr>
                    <th class="text-left text-uppercase font-weight-bold">
                      S/N
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Name
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Email
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Phone
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading && admin.length < 1" class="text-center">
                    <td colspan="5">Loading...</td>
                  </tr>
                  <tr v-if="!loading && admin.length < 1" class="text-center">
                    <td colspan="5">No Record at the Moment</td>
                  </tr>
                  <tr v-else v-for="(x, index) in admin" :key="index">
                    <td>{{ index + 1
                    }}</td>
                    <td class="text-left">{{ x.first_name + ' ' + x.last_name }}</td>
                    <td class="text-left">{{ x.email }}</td>
                    <td class="text-left">{{ x.phone }}</td>
                    <td class="text-center table-actions">
                      <button class="btn btn-info btn-md mr-2" @click="handleAdminEdit(x)">Edit</button>
                      <!-- <button class="btn btn-primary mr-2" v-if="x.is_active == false"
                        @click="activateAdmin(1, x.id)">Activate</button>
                      <button class="btn btn-danger mr-2" v-else @click="activateAdmin(0, x.id)">Deactivate</button> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions class="justify-end">
                <v-btn variant="tonal" @click="adminListDialog = false">Close</v-btn>
              </v-card-actions>
            </div>
          </v-card>

        </v-dialog>
      </v-col>
    </v-row>
    <!-- <v-row justify="space-around" v-if="notificationDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="notificationDialog" width="40%">
          <v-card>
            <v-toolbar color="#007bff" title="Send Notification"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="Message" variant="outlined" v-model="notificationData.message"
                  :rules="rules"></v-text-field>
                <v-select label="Select Institution" :item-title="'name'" :item-value="'id'" :items="institutions" variant="outlined"
                  v-model="notificationData.institution_id" :rules="[requiredRules.required]"></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="notificationDialog = false;">Close</v-btn>
              <v-btn variant="tonal" background="#f80089" color="#f80089"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row> -->
    <div class="pagination mb-2">
      <div>
        <span>Page</span>
        <span>{{ currentPage }}</span>
        <span>of</span>
        <span>{{ pagination.length}}</span>
      </div>
      <div>
        <i disabled="currentPage === 1" @click="previousPage" style="cursor: pointer"><font-awesome-icon icon="chevron-left" /></i>
        <span>Previous</span>
        <div v-for="(n, i) in pagination" :key="i">
          <button class="btn" style="margin-right:0px" :class="{'btn-active': currentPage === n}" @click="changeCPage(n)">1</button>
        </div>
        <p v-if="pagination.length > 5" >...</p>
         <button class="btn " v-if="pagination.length > 5"
      :class="{ 'btn-inactive': currentPage === pagination.length }"
>{{ pagination.length }}</button>

        <span disabled="currentPage==pagination.length" @click="nextPage">Next</span>

        <i disabled="currentPage==pagination.length" @click="nextPage"><font-awesome-icon icon="chevron-right" style="cursor: pointer" /></i>
      </div>
    </div>
  </div>
</template>
<script setup>

import { ref, inject, onMounted } from 'vue';
// import { useUserStore } from '../stores'
import { makeGetRequest, makePostRequest, makePatchRequest } from '@/request';
import { toast, } from 'vue3-toastify';
// import axios from 'axios';

const swal = inject('$swal')
const form = ref(false)
const dialog = ref(false)
const campusDialog = ref(false)
const campusListDialog = ref(false)
const adminDialog = ref(false)
const adminListDialog = ref(false)
const notificationDialog = ref(false)
const loading = ref(false)
// const file = ref(null)
const search = ref('')
const formData = ref({
  name: ''
})
const adminData = ref({
  first_name: '',
  last_name: '',
  institution_id: '',
  email: '',
  phone: ''
})
// const notificationData = ref({
//   message: '',
//   institution_id: ''
// })
// const active = ref('')
const institutionId = ref('')
const edit = ref(false)
const institutions = ref([])
const campus = ref([])
const admin = ref([])
const adminId = ref('')
const campusId = ref('')

const pagination = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const recordsPerPage = pageSize.value
const lastIndex = currentPage.value * recordsPerPage

const previousPage = () => {
  if (currentPage.value !== 1) {
    currentPage.value = currentPage.value - 1
    getInstitution()
  }
}
const changeCPage = (page) => {
        currentPage.value = page
}
const nextPage = () => {
  if (currentPage.value !== lastIndex && currentPage.value < pagination.value.length) {
    currentPage.value = currentPage.value + 1
    getInstitution()
  }
}

const rules = ref([
  (v) => !!v || 'Field is required',
  (v) => (v && v.length >= 3) || 'Field length must be at least 3 characters',
])
const requiredRules = ref({
  required: value => !!value || 'Field is required',
})
const emailRules = ref([
  (v) => !!v || 'E-mail is required',
  (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
],
)
const PhoneRules = ref([
  (v) => !!v || 'Field is required',
  (v) => /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|909|908|911|912|913|914|915|916|917|919)([0-9]{7})$/.test(v) || 'Phone Number must be valid'
],
)
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: 'custom-swal-popup'// Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false
  });
}
// ^(0(70|80|81|90|81)|\\+234(70|80|81|90|81))(\\d{8})$
const getInstitution = () => {
  preLoader()
  loading.value = true
  let request = {
    what: 'GetInstitution',
    params: {
      search: ''
    }
  }
  if (search.value !== '') request.params.search = search.value.toLowerCase()
  makeGetRequest(request)
    .then(res => {
      institutions.value = res.data.results;
      pagination.value = res.data.links.numbered
      loading.value = false
      swal.close()
    })
    .catch(error => {
      swal.close()
      loading.value = false
      toast.error(error)
    })
}
const getCampus = (id) => {
  preLoader()
  let request = {
    what: 'GetCampus',
    params: {
      institution_id: id
    }
  }
  makeGetRequest(request)
    .then(res => {
      campus.value = res.data.results;
      campusListDialog.value = true
      swal.close()
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const getAdmin = (id) => {
  institutionId.value = id
  preLoader()
  let request = {
    what: 'GetAdmin',
    params: {
      institution_id: id
    }
  }
  makeGetRequest(request)
    .then(res => {
      admin.value = res.data.admins.results;
      adminListDialog.value = true
      swal.close()
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const clearForm = () => {
  formData.value.name = ''
}
const clearAdminForm = () => {
  adminData.value.email = ''
  adminData.value.first_name = ''
  adminData.value.last_name = ''
  adminData.value.phone = ''
  adminData.value.institution_id = ''
}
const createInstitution = () => {
  if (!form.value) return
  preLoader()
  dialog.value = false

  let request = {
    what: 'CreateInstitution',
    data: formData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        swal.close()
        clearForm()
        getInstitution()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      swal.close()
      clearForm()
      toast.error(error)
    })

}
const createCampus = () => {
  if (!form.value) return
  preLoader()
  campusDialog.value = false

  let request = {
    what: 'CreateCampus',
    data: formData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        swal.close()
        getInstitution()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })

}
const createAdmin = () => {
  if (!form.value) return
  preLoader()
  adminDialog.value = false

  let request = {
    what: 'CreateAdmin',
    data: adminData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        swal.close()
        clearAdminForm()
        getAdmin()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })

}
const handleEdit = (institution) => {
  formData.value.name = institution.name
  institutionId.value = institution.id
  edit.value = true
  dialog.value = true
}
const handleCampusEdit = (campus) => {
  formData.value.name = campus.name
  formData.value.institution_id = institutionId.value
  campusId.value = campus.id
  edit.value = true
  campusDialog.value = true
}
const handleAdminEdit = (admin) => {
  adminData.value.first_name = admin.first_name
  adminData.value.last_name = admin.last_name
  adminData.value.email = admin.email
  adminData.value.phone = admin.phone
  adminId.value = admin.id
  edit.value = true
  adminDialog.value = true
}
const editInstitution = () => {
  if (!form.value) return
  preLoader()
  dialog.value = false

  let request = {
    what: 'EditInstitution',
    data: formData.value,
    id: institutionId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        swal.close()
        getInstitution()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const editCampus = () => {
  if (!form.value) return
  preLoader()
  campusListDialog.value = false
  campusDialog.value = false
  let request = {
    what: 'EditCampus',
    data: formData.value,
    id: campusId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        swal.close()
        getInstitution()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const editAdmin = () => {
  if (!form.value) return
  preLoader()
  adminListDialog.value = false
  adminDialog.value = false
  let request = {
    what: 'EditAdmin',
    data: adminData.value,
    id: adminId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearAdminForm()
        swal.close()
        getInstitution()
      }
      else {
        clearAdminForm()
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearAdminForm()
      swal.close()
      toast.error(error)
    })
}
const activate = (id, status) => {
  preLoader()
  let request = {
    what: 'ActivateInstitution',
    id: id,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        swal({
          title: 'Successful',
          text: res.msg,
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            formData.value.name = ''
            formData.value.institution_id = ''
            getInstitution()
            swal.close()
          }
        })
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
const activateAdmin = (status, id) => {
  adminListDialog.value = false
  preLoader()
  let request = {
    what: 'ActivateAdmin',
    id: id,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        swal({
          title: 'Successful',
          text: res.msg,
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            formData.value.name = ''
            formData.value.institution_id = ''
            getInstitution()
            swal.close()
          }
        })
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
const activateCampus = (status) => {
  campusListDialog.value = false
  preLoader()
  let request = {
    what: 'EditCampus',
    id: institutionId.value,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        swal({
          title: 'Successful',
          text: res.msg,
          icon: 'success',
        }).then((result) => {
          if (result.value) {
            getInstitution()
            swal.close()
          }
        })
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
onMounted(() => {
  getInstitution()
})
</script>
<script>
export default {
  name: 'InstitutionPage',
}
</script>
<style scoped>
.pagination {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination div {
  display: flex;
  align-items: center;
  gap: 10px;

}

.pagination div span,
.pagination div i {
  font-size: 14px;
}

@media screen and (max-width: 720px) {
  .pagination {
    flex-direction: column;
    gap: 15px;
  }
}
</style>