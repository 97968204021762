<template>
    <div class="page-container">
       <header-layout></header-layout>
        <side-layout></side-layout>
        <div class="main-container">
            <router-view></router-view>
        </div>
        <footer-layout></footer-layout>
    </div>
</template>
<script>
import HeaderLayout from './Header.vue'
import SideLayout from './Sidebar.vue'
import FooterLayout from './Footer.vue'
export default{
    name: "BaseLayout",
    
    components:{HeaderLayout, SideLayout, FooterLayout}
}
</script>
<style>
    
</style>