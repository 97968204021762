<template>
  <div class="main-wrapper" style="background-color: #fff;">
    <div class="search-bar">
      <input
        type="search"
        name=""
        id=""
        placeholder="Search"
        v-model="search"
        @change="getStudent"
      />
      <div class="d-flex align-center g-10">
        <v-select
                  v-if="user.role.toLowerCase() == 'system admin'"
                  label="Select Institution"
                  :item-title="'name'"
                  :item-value="'id'"
                  :items="institutions"
                  variant="outlined"
                density="compact"
                class="mr-4"
                style="height: 38px; width: 300px;"
                  v-model="formData.institution_id"
                  @update:modelValue="getStudent"
                ></v-select>
        <button @click="studentDialog = true" class="mr-2" >
          Upload Student / Staff
        </button>
      </div>
    </div>
    <div class="complaint-table">
      <v-table class="table" hover="true">
        <thead class="table-header__color">
          <tr>
            <th class="text-left text-uppercase font-weight-bold">S/N</th>
            <th class="text-center text-uppercase font-weight-bold">Name</th>
            <th class="text-center text-uppercase font-weight-bold">Email</th>
            <th class="text-center text-uppercase font-weight-bold">
              Phone Number
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              User Type
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Matric No/File No
            </th>
            <!-- <th class="text-center text-uppercase font-weight-bold">Actions</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && students.length < 1" class="text-center">
            <td colspan="6">Loading...</td>
          </tr>
          <tr v-if="!loading && students.length < 1" class="text-center">
            <td colspan="6">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(x, index) in students" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="text-center">{{ x.first_name }} {{ x.last_name }}</td>
            <td class="text-center">{{ x.email ? x.email : "-" }}</td>
            <td class="text-center">{{ x.phone ? x.phone : "-" }}</td>
            <td class="text-center">
              {{ x.user_type === 5 ? "Student" : "Staff" }}
            </td>
            <td class="text-center">{{ x.identifier }}</td>
          </tr>
        </tbody>
      </v-table>
    </div>
    <v-row justify="space-around" v-if="studentDialog == true">
      <v-col cols="auto">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="studentDialog"
          persistent
          class="dialog"
        >
          <v-card>
            <v-toolbar
              color="#007bff"
              title="Upload Student/Staff List"
            ></v-toolbar>
            <v-card-actions class="justify-end">
              <a
                href="../media/student-upload-sample.xlsx"
                style="text-decoration: none"
                download
                class="btn btn-primary download-btn align-self-end mr-5"
              >
                Sample (Student)
              </a>
              <a
                href="../media/staff-upload-sample.xlsx"
                style="text-decoration: none"
                download
                class="btn btn-primary download-btn align-self-end mr-5"
              >
                Sample (Staff)
              </a>
              <!-- <v-btn variant="tonal" background="#028A0F" color="#028A0F" 
              >Sample (Student)</v-btn>
              <v-btn variant="tonal" background="#028A0F" color="#028A0F" 
              >Sample (Staff)</v-btn> -->
            </v-card-actions>
            <v-card-text>
              <v-form v-model="form">
                <v-select
                  v-if="user.role.toLowerCase() == 'system admin'"
                  label="Institution"
                  :item-title="'name'"
                  :item-value="'id'"
                  :items="institutions"
                  variant="outlined"
                  v-model="formData.institution_id"
                  :rules="[requiredRules.required]"
                  clearable
                ></v-select>
                <v-select
                  label="Category"
                  :items="['student', 'staff']"
                  variant="outlined"
                  v-model="formData.category"
                  :rules="[requiredRules.required]"
                  clearable
                ></v-select>
                <v-select
                  label="Action"
                  :items="['activate', 'deactivate']"
                  variant="outlined"
                  v-model="formData.action"
                  :rules="[requiredRules.required]"
                  clearable
                ></v-select>
                <v-file-input
                  label="Upload Student/Staff File"
                  variant="outlined"
                  ref="file"
                  prepend-icon=""
                  required
                  :rules="rules"
                  class="mt-5"
                ></v-file-input>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="
                  studentDialog = false;
                  clearForm();
                "
                >Close</v-btn
              >
              <v-btn
                v-if="edit"
                variant="tonal"
                background="#f80089"
                color="#f80089"
                @click="editCampus"
                :disabled="!form"
                >Submit</v-btn
              >
              <v-btn
                v-else
                variant="tonal"
                background="#f80089"
                color="#f80089"
                @click="createStudent"
                :disabled="!form"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <div class="pagination mb-2">
      <div>
        <span>Page</span>
        <span>{{ currentPage }}</span>
        <span>of</span>
        <span>{{ pagination.length }}</span>
      </div>
      <div>
        <i
          :disabled="currentPage === 1"
          @click="previousPage"
          style="cursor: pointer"
          ><font-awesome-icon icon="chevron-left"
        /></i>
        <span>Previous</span>
        <div v-for="(n, i) in pagination" :key="i">
          <button
            class="btn"
            style="margin-right: 0px"
            :class="{ 'btn-active': currentPage === n }"
            @click="changeCPage(n)"
          >
            {{ i + 1 }}
          </button>
        </div>
        <p v-if="pagination.length > 5">...</p>
        <button
          class="btn"
          v-if="pagination.length > 5"
          :class="{ 'btn-inactive': currentPage === pagination.length }"
        >
          {{ pagination.length }}
        </button>

        <span :disabled="currentPage==pagination.length" @click="nextPage"
          >Next</span
        >

        <i :disabled="currentPage==pagination.length" @click="nextPage"
          ><font-awesome-icon icon="chevron-right" style="cursor: pointer"
        /></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import { useUserStore } from "../stores";
import { makeGetRequest, makePostRequest, makePatchRequest } from "@/request";
import { toast } from "vue3-toastify";
import is401 from "@/utils/is401";
// import axios from 'axios';

const swal = inject("$swal");
const studentDialog = ref(false);
const file = ref(null);
const search = ref("");
const formData = ref({
  category: "",
  action: "",
  institution_id: "",
});
const user = useUserStore();
const loading = ref(false);
const students = ref([]);
const institutions = ref([]);
const form = ref(false);

const pagination = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);
const recordsPerPage = pageSize.value;
const lastIndex = currentPage.value * recordsPerPage;

const previousPage = () => {
  if (currentPage.value !== 1) {
    currentPage.value = currentPage.value - 1;
    getStudent();
  }
};
const changeCPage = (page) => {
  currentPage.value = page;
  getStudent()
};
const nextPage = () => {
  if (
    currentPage.value !== lastIndex &&
    currentPage.value < pagination.value.length
  ) {
    currentPage.value = currentPage.value + 1;
    getStudent();
  }
};
const clearForm = () => {
  formData.value.name = "";
};
const rules = ref([
  (v) => !!v || "File is required",
  (v) => (v && v.length > 0) || "File is required",
  (v) => {
    if (!v || v.length === 0) return true; // If no file uploaded, skip validation

    const allowedExtensions = [".xlsx", ".xls", ".csv"];

    const invalidFiles = v.filter((file) => {
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();
      return !allowedExtensions.includes("." + fileExtension);
    });

    return (
      invalidFiles.length === 0 ||
      "Invalid file type. Allowed types: XLS, XLSX, CSV"
    );
  },
]);
const requiredRules = ref({
  required: (value) => !!value || "Field is required",
});

const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: "custom-swal-popup", // Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
};
const getStudent = () => {
  if (user.role.toLowerCase() == "system admin" && formData.value.institution_id == ''){
    return
  } else {
  preLoader();
  loading.value = true;
  let request = {
    what: "GetStudents",
    params: {
      search: "",
      institution_id: "",
      page: currentPage.value,
      pageSize: pageSize.value,
    },
  };
  if (search.value !== "") request.params.search = search.value.toLowerCase();
  if (user.role.toLowerCase() == "system admin") {
    request.params.institution_id = formData.value.institution_id
  } else {
    request.params.institution_id = user.institutionID;
  }
  makeGetRequest(request)
    .then((res) => {
      console.log(res);
      students.value = res.data.results;
      pagination.value = res.data.links.numbered;
      loading.value = false;
      swal.close();
    })
    .catch((error) => {
      if (error.includes("logout and login")) {
        is401(401);
      }
      swal.close();
      loading.value = false;
      toast.error(error);
    });
  }
};
const getInstitution = () => {
  // swal.showLoading({allowOutsideClick: false})
  let request = {
    what: "GetInstitution",
  };
  makeGetRequest(request)
    .then((res) => {
      institutions.value = res.data.results;
    })
    .catch((error) => {
      toast.error(error);
    });
};
const createStudent = () => {
  if (!form.value) return;
  preLoader();
  studentDialog.value = false;

  const Data = new FormData();
  Data.append("action", formData.value.action);
  Data.append("category", formData.value.category);
  if (user.role.toLowerCase() == "system admin") {
    Data.append("institution_id", formData.value.institution_id);
  } else {
    Data.append("institution_id", user.institutionID);
  }

  if (file.value !== null) {
    Data.append("file", file.value.files[0]);
  }

  let request = {
    what: "CreateStudent",
    data: Data,
  };
  makePostRequest(request)
    .then((res) => {
      if (res.status) {
        toast.success(res.msg);
        clearForm();
        swal.close();
        getStudent();
      } else {
        clearForm();
        toast.error(res.msg);
      }
    })
    .catch((error) => {
      clearForm();
      swal.close();
      toast.error(error);
    });
};
onMounted(() => {
  if (user.role.toLowerCase() !== "system admin") {
    getStudent();
  } else {
    getInstitution();
  }
});
</script>
<script>
export default {
  name: "StudentPage",
};
</script>
<style scoped>
.main-wrapper {
  width: 100%;
}

.main-wrapper button {
  margin: 0;
}

.modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.486);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 89%;
  margin: 0 auto;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-content {
  background: #fff;
  padding-bottom: 20px;
  border-radius: 4px;
}

.complaints-text {
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  width: clamp(35vw, 480px, 95vw);
}

.icon-cancel {
  cursor: pointer;
}

nav {
  background: transparent !important;
}

.pagination {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination div span,
.pagination div i {
  font-size: 14px;
}

@media screen and (max-width: 720px) {
  .pagination {
    flex-direction: column;
    gap: 15px;
  }
}
</style>
