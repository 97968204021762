<template>
  <div id="map" style="width: auto; height: auto">
    <div class="status-container">
      <div class="pending-status" v-if="user.role == 'System Admin'">
        <h4>Active Institution</h4>
        <p>{{ dashboard ? dashboard?.active_institutions : 0 }}</p>
      </div>
      <div class="resolved-status" v-if="user.role == 'System Admin'">
        <h4>Inactive Institution</h4>
        <p>{{ dashboard ? dashboard?.inactive_institutions : 0 }}</p>
      </div>
      <div class="total-status" v-if="user.role == 'System Admin'">
        <h4>Campus</h4>
        <p>{{ dashboard ? dashboard?.campus : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'System Admin'">
        <h4>Admins</h4>
        <p>{{ dashboard ? dashboard?.admins : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin'">
        <h4>Active Campus</h4>
        <p>{{ dashboard ? dashboard?.active_campus : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin'">
        <h4>Inactive Campus</h4>
        <p>{{ dashboard ? dashboard?.inactive_campus : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin'">
        <h4>Active Staff/Student</h4>
        <p>{{ dashboard ? dashboard?.active_student_staff : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin'">
        <h4>Inactive Staff/Student</h4>
        <p>{{ dashboard ? dashboard?.student_staff : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin'">
        <h4>Head of Security</h4>
        <p>{{ dashboard ? dashboard?.head_of_security : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin' || user.role == 'Security Agent'">
        <h4>Report</h4>
        <p>{{ dashboard ? dashboard?.report : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin' || user.role == 'Security Agent'">
        <h4>Treated Report</h4>
        <p>{{ dashboard ? dashboard?.treated_report : 0 }}</p>
      </div>
      <div class="today-status" v-if="user.role == 'Institution Admin' || user.role == 'Security Agent'">
        <h4>Untreated Report</h4>
        <p>{{ dashboard ? dashboard?.untreated_report : 0 }}</p>
      </div>
      <div
        class="today-status"
        v-if="
          user.role == 'Institution Admin' || user.role == 'Head of Security'
        "
      >
        <h4>Responder</h4>
        <p>{{ dashboard ? dashboard?.security_agent : 0 }}</p>
      </div>
    </div>
    <div class="complaint-table mt-5" v-if="user.role === 'System Admin'">
      <p class="mb-3">Recently Created Institution</p>
      <v-table class="table">
        <thead class="table-header__color">
          <tr>
            <th class="text-left text-uppercase font-weight-bold">S/N</th>
            <th class="text-center text-uppercase font-weight-bold">Name</th>
            <th class="text-center text-uppercase font-weight-bold">Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && institutions.length < 1" class="text-center">
            <td colspan="4">Loading...</td>
          </tr>
          <tr v-if="!loading && institutions.length < 1" class="text-center">
            <td colspan="4">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(institution, index) in institutions" :key="index">
            <td class="text-left">{{ index + 1 }}</td>
            <td class="text-center">{{ institution.name }}</td>
            <td class="text-center">{{ institution.code }}</td>
          </tr>
        </tbody>
      </v-table>
      <router-link to="institution" class="d-flex justify-end"
        >See All</router-link
      >
    </div>
    <div class="complaint-table mt-5" v-if="user.role == 'Institution Admin'">
      <p class="mb-3">Recently Created Campus</p>
      <v-table class="table" hover="true">
        <thead class="table-header__color">
          <tr>
            <th class="text-left text-uppercase font-weight-bold">S/N</th>
            <th class="text-center text-uppercase font-weight-bold">Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && campus.length < 1" class="text-center">
            <td colspan="3">Loading...</td>
          </tr>
          <tr v-if="!loading && campus.length < 1" class="text-center">
            <td colspan="3">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(x, index) in campus" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="text-center">{{ x.name }}</td>
          </tr>
        </tbody>
      </v-table>
      <router-link to="campus" class="d-flex justify-end">See All</router-link>
    </div>
    <div class="complaint-table mt-5" v-if="user.role == 'Head of Security'">
      <p class="mb-3">Recently Created Responders</p>
          <v-table class="table" hover="true">
            <thead class="table-header__color">
              <tr>
                <th class="text-left text-uppercase font-weight-bold">
                  S/N
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Name
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Email
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Phone
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading && responder.length < 1" class="text-center">
                <td colspan="5">Loading...</td>
              </tr>
              <tr v-if="!loading && responder.length < 1" class="text-center">
                <td colspan="5">No Record at the Moment</td>
              </tr>
              <tr v-else v-for="(x, index) in responder" :key="index">
                <td>{{ index + 1
                }}</td>
                <td class="text-left">{{ x.first_name + ' ' + x.last_name}}</td>
                <td class="text-left">{{ x.email }}</td>
                <td class="text-left">{{ x.phone }}</td>
               
              </tr>
            </tbody>
          </v-table>
          <router-link to="responder" class="d-flex justify-end">See All</router-link>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch, inject } from "vue";
import { makeGetRequest } from "@/request";
import { toast } from "vue3-toastify";
import { useUserStore } from "@/stores";
import is401 from "@/utils/is401";

const user = useUserStore();
const role = user.role;
const dashboard = ref("");
const search = ref("");
const loading = ref(false);
const swal = inject("$swal");
const institutions = ref([]);
const responder = ref([])
const campus = ref([]);
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: "custom-swal-popup", // Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
};

const getAnalytics = () => {
  preLoader();
  loading.value = true;
  let request = {
    what: "GetAnalytics",
  };
  makeGetRequest(request)
    .then((res) => {
      dashboard.value = res.data;
      console.log(dashboard.value);
      // responder.value = res.data.hos.results;
      // pagination.value = res.data.hos.links.numbered
      loading.value = false;
      swal.close();
    })
    .catch((error) => {
      swal.close();
      loading.value = false;
      toast.error(error);
    });
};

const getInstitution = () => {
  let request = {
    what: "GetInstitution",
  };

  makeGetRequest(request)
    .then((res) => {
      institutions.value = res.data.results.slice(0, 5);
    })
    .catch((error) => {
      toast.error(error);
    });
};
const getCampus = () => {
  let request = {
    what: "GetAdminCampus",
  };
  makeGetRequest(request)
    .then((res) => {
      campus.value = res.data.results.slice(0, 5);
    })
    .catch((error) => {
      if (error.includes("logout and login")) {
        is401(401);
      }
      toast.error(error);
    });
};
const getResponder = () => {
    let request = {
        what: 'GetSecurityResponder',
    }
    makeGetRequest(request)
        .then(res => {
            responder.value = res.data.hos.results.slice(0, 5);
        })
        .catch(error => {
            toast.error(error)
        })
}
onMounted(() => {
  getAnalytics();
  if (user.role === "System Admin") {
    getInstitution();
  }
  if (user.role === "Institution Admin") {
    getCampus();
  }
  if (user.role === "Head of Security") {
    getResponder();
  }
});
</script>
<script>
export default {
  name: "DashBoard",
};
</script>

<style scoped>
.main-container {
  background-color: #000 !important;
}
</style>
