import { initializeApp } from 'firebase/app'
// import { getFirestore, collection } from 'firebase/firestore'
// import { useCollection } from 'vuefire'

// firebase init - add your own config here
// const firebaseConfig = {
//     apiKey: 'AIzaSyDLRcwcS-hVc4H8Yejx15PMWWZ934CXgLs',
//     authDomain: 'https://ntisa-22a97.firebaseapp.com',
//     databaseURL: 'https://ntisa-22a97.firebaseio.com',
//     projectId: 'ntisa-22a97',
//     storageBucket: 'gs://ntisa-22a97.appspot.com',
//     messagingSenderId: '724148179627',
//     appId: '1:724148179627:android:0da9e87a1b0c27bb959dee'
// }
export const fb = initializeApp({
    apiKey: 'AIzaSyCWugqPI9pOfAENPd4B-DmApRfBtNkDWbo',
    authDomain: "https://emergency-notification-s-e1f75.firebaseapp.com",
    databaseURL: "https://emergency-notification-s-e1f75.firebaseapp.com",
    projectId: "emergency-notification-s-e1f75",
    storageBucket: "gs://emergency-notification-s-e1f75.appspot.com",
    messagingSenderId: "430671654618",
    appId: "1:430671654618:web:8c00ba335a9fd240a1bfe3",
    measurementId: "G-VXB821WJHG"
  });
  
// const db = getFirestore(fb)
// export const location = useCollection(collection(db, 'user_locations'))