/*
 * Main and demo navigation arrays
 */

export default {
    'main': [
      {
        name: 'Institutions',
        to: '/institution',
        icon: 'fa-institution',
        role: 'System Admin'
      },
      {
        name: 'Campuses',
        to: '/campus',
        icon: 'fa-institution',
        role: 'Institution Admin'
      },
      {
        name: 'Responders',
        to: '/responder',
        icon: 'fa-message',
        role: 'Head of Security'
      },
      {
        name: 'Reports',
        to: '/report',
        icon: 'fa-tag',
        role: ['Institution Admin', 'Head of Security']
      },
      {
        name: 'Global Maps',
        to: '/map',
        icon: 'fa-map-location',
        role: ['Institution Admin', 'Head of Security']
      },
      
    ]
  }
  