<template>
    <header class="header-container">
        <i class="header-menu"><font-awesome-icon icon="fa-solid fa-bars" @click="showMenu"/></i>
        <!-- <img src="../../public/icon-menu.svg" alt=""  class="ml-2"> -->
        <!-- <p style="margin-right: 20px;font-size: 12px;">{{user.name}}</p> -->
        
           <div class="profile-user__wrapper">
            <i class="profile-user"><font-awesome-icon icon="fa-solid fa-user"/></i>
            
                <v-menu>
                <template v-slot:activator="{ props }">
                  <font-awesome-icon icon="fa-solid fa-chevron-down" v-bind="props"/>

                </template>

                <v-list>
                  <v-list-item class="list-item-btn">
                    <!-- <v-list-item-title> -->
                    <div class="d-flex align-center" style="gap: 0.4rem;">
                        
                            <font-awesome-icon icon="fa-solid fa-user"/>
                            <router-link to="/profile">
                        <button class="btn-submit list-item-btn btn-info">Profile & Settings</button>
                        </router-link>
                    </div>
                    

                  </v-list-item>
                  <v-list-item>
                    <div class="d-flex align-center" style="gap: 0.4rem; color: red;">
                        <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
                        <button class="log-out-btn" @click="logoutUser">Logout</button>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
           </div>
           
            <!-- <font-awesome-icon icon="fa-solid fa-right-from-bracket" /> -->
        
        <!-- <button class="log-out-btn" @click="logoutUser">Log Out</button> -->
    </header>
    <div class="nav-container-sm" v-if="show">
        <div class="nav-container-sm-header">
            <img src="../../public/harlequin.svg" alt="">
            <i @click="show=false"><font-awesome-icon icon="fa-solid fa-times" size="lg"/></i>
        </div>
        <ul>
                <router-link to="/dashboard" class="nav-inactive" 
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-chalkboard" size="lg" /></i>Dashboard
                </li>
            </router-link>
            <router-link to="/institution" class="nav-inactive" v-if="user.role=='System Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-institution" size="lg" /></i>Institutions
                </li>
            </router-link>
            <router-link to="/campus" class="nav-inactive" v-if="user.role=='Institution Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-institution" size="lg" /></i>Campuses
                </li>
            </router-link>
            <router-link to="/students" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='System Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-user" size="lg" /></i>Students / Staffs
                </li>
            </router-link>
            <router-link to="/responder" class="nav-inactive" v-if="user.role=='Head of Security'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-message"  size="lg" /></i>Responders
                </li>
            </router-link>
            <router-link to="/report" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='Security Agent'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-tag"  size="lg" /></i>Reports
                </li>
            </router-link>
            <router-link to="/alert-history" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='Security Agent'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-clock-rotate-left"  size="lg" /></i>History
                </li>
            </router-link>
            <router-link to="/map" class="nav-inactive" v-if="user.role=='Institution Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-location-dot" size="lg" /></i>Global Maps
                </li>
            </router-link>
        </ul>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import menuList from '@/data/menu'
import { useUserStore } from '@/stores'
import { useRouter } from 'vue-router'
const router = useRouter()

const show = ref(false)
const user = useUserStore()
const role = user.role
const nodes = menuList.main

const showMenu = () => {
    show.value = !show.value
}

const shouldDisplay = (node, role) => {
      // Check if the userRole is included in the node's role array
      return Array.isArray(node.role) ? node.role.includes(role.value) : node.role === role.value;
    
}
const logoutUser =  () => {
    user.setLogin(false)
    user.setToken('')
    user.setRole('')
    user.setName('')
    user.setEmail('')
    user.setPassword('')
    router.push('/')
}
</script>
<script>
export default {
    name: 'HeaderLayout',
}
</script>