<template>
 <div class="main-wrapper" style="background-color: #fff;">
  
  <v-form v-model="form" class="profile-form">
    <h2 class="text-base font-semibold leading-7 text-gray-900 mb-6">Profile Information</h2>
    <div class="form-flex">
      <v-text-field label="First Name" variant="outlined" v-model="formData.first_name" type="text" required :rules="[rules.required]"></v-text-field>
      <v-text-field label="Last Name" variant="outlined" v-model="formData.last_name" type="text" required :rules="[rules.required]"></v-text-field>
    </div>
    <div class="form-flex">
      <v-text-field label="Email" variant="outlined" v-model="formData.email" type="email" required  :rules="emailRules"></v-text-field>
      <v-text-field label="Phone" variant="outlined" v-model="formData.phone" type="tel" required :rules="[rules.required]"></v-text-field>
    </div>
    <div class="col-span-full">
            <label for="photo" class="block text-sm font-medium leading-6 text-gray-900">Photo</label>
            <div class="mt-2 flex items-center gap-x-3">
              <v-avatar><img class="inline-block h-12 w-12 rounded-full ring-2 ring-white" :src="formData.photo" v-if="formData.photo !== null" />
              <UserCircleIcon class="h-12 w-12 text-gray-300" aria-hidden="true" v-else /></v-avatar>
              <button type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="change=!change">Change</button>
              
            </div>
            <v-file-input v-if="change==true" label="Upload Photo" variant="outlined" ref="file" required :rules="[rules.required]" class="mt-5"></v-file-input>
    </div>
    <v-btn class="my-4 text-left" color="#e83e8c" height="40" text="Update Profile" variant="flat"
             :disabled="!form" type="submit" @click.prevent="updateProfile"></v-btn>
  </v-form>

  <v-form v-model="form" class="profile-form" ref="formRef">
    <h2 class="text-base font-semibold leading-7 text-gray-900 mb-6">Update Password</h2>
    <div class="form-flex">
      <v-text-field label="Old Password" variant="outlined" v-model="passwordData.old_password" type="password" required :rules="[rules.required]"></v-text-field>
      <v-text-field label="New Password" :rules="pwdRules" variant="outlined" v-model="passwordData.new_password" type="password" required></v-text-field>
      <v-text-field label="Confirm Password" variant="outlined" :rules="pwdConfirm" v-model="confirmPassword" type="password" required ></v-text-field>
    </div>
    <v-btn class="my-4 text-left" color="#e83e8c" height="40" text="Update Password" variant="flat"
             :disabled="!form" type="submit" @click.prevent="updatePassword"></v-btn>
  </v-form>
 </div>
</template>

<script setup>
import { ref, inject, onMounted, computed} from 'vue';
import {  UserCircleIcon } from '@heroicons/vue/24/solid'
import { makeGetRequest, makePostRequest } from '@/request';
import { toast } from 'vue3-toastify';

const file = ref(null)
const formRef = ref(null)
const resetForm = () => {
      if (formRef.value) {
        formRef.value.reset();
      }
};
const confirmPassword = ref('')
// import axios from 'axios';

const swal = inject('$swal')
const profileInfo = ref('')

const form = ref(false)
const rules = ref({
        required: value => !!value || 'Field is required',
})
const emailRules = ref([
             (v) => !!v || 'E-mail is required',
             (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
)
const pwdRules = ref([
  v => !!v || "Password required",
]);
const pwdConfirm = ref( [
  v => !!v || "Confirm password",
  v => v === passwordData.value.new_password || "Passwords do not match"
]);
const change = ref(false)
const formData = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  photo: ''
})
const passwordData = ref({
  old_password: '',
  new_password: ''
})
const clearForm = () =>{
  passwordData.value.old_password
}
const setFormData = () =>{
  if (profileInfo.value !== ''){
    formData.value = {
      first_name: profileInfo.value.first_name,
      last_name: profileInfo.value.last_name,
      email: profileInfo.value.email,
      phone: profileInfo.value.phone,
      photo: profileInfo.value.photo
    }
  }
  
}
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: 'custom-swal-popup'// Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false
  });
}
const getProfile = () => {
  preLoader()
  let request = {
    what: 'ViewProfile'
  }
  makeGetRequest(request)
    .then(res => {
      profileInfo.value = res.data;
      setFormData()
      swal.close()
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const updateProfile = () => {
  
  preLoader()
  const Data = new FormData();
  Data.append('first_name', formData.value.first_name);
  Data.append('last_name', formData.value.last_name);
  Data.append('email', formData.value.email);
  Data.append('phone', formData.value.phone);
  if (file.value !== null) {
    Data.append('photo', file.value.files[0]);
  }
  
  var request = {
    what: "UpdateProfile",
    data: Data
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        change.value = false
        toast.success(res.msg)
        getProfile()
        swal.close()
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
const updatePassword = () => {
  // if (!form.value) return
  preLoader()
  var request = {
    what: "UpdatePassword",
    data: passwordData.value
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        toast.success(res.msg)
        getProfile()
        resetForm()
        swal.close()
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
onMounted(() =>{
  getProfile()
})
</script>
<script>
export default {
    name: 'ProfilePage',
}
</script>