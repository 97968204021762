<template>
    <footer>
        <div class="footer-container">
        <div>
            <p>&copy; Copyright {{getCurrentYear()}}. ENS</p>
        </div>
        <div class="footer-policy">
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
        </div>
        <div class="footer-brands">
            <font-awesome-icon icon="fa-brands fa-facebook" size="lg"/>
            <font-awesome-icon icon="fa-brands fa-x-twitter" />
            <font-awesome-icon icon="fa-brands fa-instagram" size="lg"/>
            <font-awesome-icon icon="fa-brands fa-youtube" size="lg"/>
        </div>
    </div>
    </footer>
</template>
<script setup>
    const getCurrentYear = () =>{
    return new Date().getFullYear()
}
</script>

<script>
export default {
    name: 'FooterLayout'
}
</script>