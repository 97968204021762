import {createRouter, createWebHistory} from "vue-router";
import UserLoginPage from "../views/UserLoginPage.vue"
import BaseLayout from "../components/Base.vue"
import ReportPage from "../views/Report.vue"
import ResponderPage from "../views/Responder.vue"
import UserVerification from "../views/UserVerification.vue"
import InstitutionPage from "../views/Institution.vue"
import CampusPage from "../views/Campus.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import MapLayout from "../views/Map.vue"
import ProfilePage from "../views/Profile.vue"
import GlobalMap from "../views/GlobalMap.vue"
import StudentPage from "../views/Students.vue"
import Dashboard from "../views/Dashboard.vue"
import AlertHistory from "../views/AlertHistory.vue"

const routes =[
    {
        path: '/',
        name: 'Login Page',
        component: UserLoginPage,
        meta:{
          public: true, // Allow access to even if not logged in
          onlyWhenLoggedOut: true
        }
    },
    {
      path: '/verify',
      name: 'User Verification',
      component: UserVerification,
      meta:{
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta:{
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    }
},
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/report',
            name: 'Report',
            component: ReportPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/responder',
            name: 'Responder',
            component: ResponderPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/institution',
            name: 'Institution',
            component: InstitutionPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/campus',
            name: 'Campus',
            component: CampusPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/students',
            name: 'Students',
            component: StudentPage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/location/:id',
            name: 'Map',
            component: MapLayout,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/profile',
            name: 'Profile Page',
            component: ProfilePage,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/map',
            name: 'Global Map',
            component: GlobalMap,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/',
        component: BaseLayout,
        children: [
          {
            path: '/alert-history',
            name: 'History',
            component: AlertHistory,
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      
]

const routers = createRouter({
    history: createWebHistory(),
    routes
})

export default routers