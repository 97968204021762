<template>
  <div class="main-wrapper" style="background-color: #fff;">

    <div class="search-bar">
      <input type="search" name="" id="" placeholder="Search" v-model="search" @change="getCampus">
      <div class="d-flex">
        <button class="mr-2" @click="notificationDialog = true"><font-awesome-icon icon="fa-solid fa-bell"
            size="lg" /></button>
        <button @click="campusDialog = true" class="mr-2">Create Campus</button>
        <button @click="securityDialog = true" class="mr-2">Create Security</button>
        <button @click="responderDialog = true" class="mr-2">Create Responder</button>
      </div>
    </div>
    <div class="complaint-table">
      <v-table class="table" hover="true">
        <thead class="table-header__color">
          <tr>
            <th class="text-left text-uppercase font-weight-bold">
              S/N
            </th>
            <th class="text-left text-uppercase font-weight-bold">
              Name
            </th>
            <th class="text-center text-uppercase font-weight-bold">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && campus.length < 1" class="text-center">
            <td colspan="3">Loading...</td>
          </tr>
          <tr v-if="!loading && campus.length < 1" class="text-center">
            <td colspan="3">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(x, index) in campus" :key="index">
            <td>{{ index + 1
            }}</td>
            <td class="text-left">{{ x.name }}</td>
            <td class="text-center table-actions">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <font-awesome-icon icon="fa-solid fa-ellipsis" v-bind="props" size="xl" class="" />

                </template>

                <v-list>
                  <v-list-item class="list-item-btn">
                    <button class="btn btn-info" @click="handleCampusEdit(x)">Edit</button>
                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-info btn-md" @click="getSecurity(x.id)">View Security</button>
                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-info btn-md" @click="getResponder(x.id)">View Responder</button>
                  </v-list-item>
                  <v-list-item>
                    <button class="btn btn-primary" v-if="x.active == false" @click="activate(1, x.id)">Activate</button>
                    <button class="btn btn-danger" v-else @click="activate(0, x.id)">Deactivate</button>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <div class="btn-menu-lg">
                <button class="btn btn-info btn-md mr-2" @click="handleCampusEdit(x)">Edit</button>
                <button class="btn btn-info btn-md mr-2" @click="getSecurity(x.id)">View Security</button>
                <button class="btn btn-info btn-md mr-2" @click="getResponder(x.id)">View Responder</button>

                <button class="btn btn-primary mr-2" v-if="x.active == false" @click="activate(1, x.id)">Activate</button>
                <button class="btn btn-danger mr-2" v-else @click="activate(0, x.id)">Deactivate</button>
              </div> -->
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
    <v-row justify="space-around" v-if="campusDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="campusDialog" persistent class="dialog">
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Campus' : 'Add a New Campus'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="Name" variant="outlined" v-model="formData.name" :rules="rules"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="campusDialog = false; clearForm()">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" @click="editCampus"
                :disabled="!form">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click="createCampus"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="securityDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="securityDialog" persistent class="dialog">
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Security' : 'Add a New Head of Security'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="First Name" variant="outlined" v-model="securityData.first_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Last Name" variant="outlined" v-model="securityData.last_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Email" variant="outlined" v-model="securityData.email" type="email"
                  :rules="emailRules"></v-text-field>
                <v-text-field label="Phone" variant="outlined" v-model="securityData.phone" type="tel"
                  :rules="phoneRules"></v-text-field>
                <v-select label="Select Campus" :item-title="'name'" :item-value="'id'" :items="campus" variant="outlined"
                  v-model="securityData.campus_id" :rules="[requiredRules.required]" clearable></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="securityDialog = false; clearSecurityForm()">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" @click="editSecurity"
                :disabled="!form">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click="createSecurity"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="responderDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="responderDialog" persistent class="dialog">
          <v-card>
            <v-toolbar color="#007bff" :title="edit ? 'Edit Responder' : 'Add a New Responder'"></v-toolbar>

            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="First Name" variant="outlined" v-model="securityData.first_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Last Name" variant="outlined" v-model="securityData.last_name"
                  :rules="rules"></v-text-field>
                <v-text-field label="Email" variant="outlined" v-model="securityData.email" type="email"
                  :rules="emailRules"></v-text-field>
                <v-text-field label="Phone" variant="outlined" v-model="securityData.phone" type="tel"
                  :rules="phoneRules"></v-text-field>
                <v-select label="Select Campus" :item-title="'name'" :item-value="'id'" :items="campus" variant="outlined"
                  v-model="securityData.campus_id" :rules="[requiredRules.required]" clearable></v-select></v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="responderDialog = false; clearSecurityForm()">Close</v-btn>
              <v-btn v-if="edit" variant="tonal" background="#f80089" color="#f80089" @click="editResponder"
                :disabled="!form">Submit</v-btn>
              <v-btn v-else variant="tonal" background="#f80089" color="#f80089" @click="createResponder"
                :disabled="!form">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <v-row justify="space-around" v-if="securityListDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-top-transition" v-model="securityListDialog" class="admin-dialog" persistent>
          <v-card>
            <v-toolbar color="#10428D" title="List of Head of Security"></v-toolbar>
            <div class="store-table shadow">

              <v-table class="table" hover="true">
                <thead>
                  <tr>
                    <th class="text-left text-uppercase font-weight-bold">
                      S/N
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Name
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Email
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Phone
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading && security.length < 1" class="text-center">
                    <td colspan="5">Loading...</td>
                  </tr>
                  <tr v-if="!loading && security.length < 1" class="text-center">
                    <td colspan="5">No Record at the Moment</td>
                  </tr>
                  <tr v-else v-for="(x, index) in security" :key="index">
                    <td>{{ index + 1
                    }}</td>
                    <td class="text-left">{{ x.first_name + ' ' + x.last_name }}</td>
                    <td class="text-left">{{ x.email }}</td>
                    <td class="text-left">{{ x.phone }}</td>
                    <td class="text-center table-actions">
                      <button class="btn btn-info btn-md mr-2" @click="handleSecurityEdit(x)">Edit</button>
                      <!-- <button class="btn btn-primary mr-2" v-if="x.is_active == false" @click="activateSecurity(1)">Activate</button>
                            <button class="btn btn-danger mr-2" v-else @click="activateSecurity(0)">Deactivate</button> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions class="justify-end">
                <v-btn variant="tonal" @click="securityListDialog = false">Close</v-btn>
              </v-card-actions>
            </div>
          </v-card>

        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around" v-if="responderListDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-top-transition" v-model="responderListDialog" class="admin-dialog" persistent>
          <v-card>
            <v-toolbar color="#10428D" title="List of Responders"></v-toolbar>
            <div class="store-table shadow">

              <v-table class="table" hover="true">
                <thead>
                  <tr>
                    <th class="text-left text-uppercase font-weight-bold">
                      S/N
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Name
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Email
                    </th>
                    <th class="text-left text-uppercase font-weight-bold">
                      Phone
                    </th>
                    <th class="text-center text-uppercase font-weight-bold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading && responder.length < 1" class="text-center">
                    <td colspan="5">Loading...</td>
                  </tr>
                  <tr v-if="!loading && responder.length < 1" class="text-center">
                    <td colspan="5">No Record at the Moment</td>
                  </tr>
                  <tr v-else v-for="(x, index) in responder" :key="index">
                    <td>{{ index + 1
                    }}</td>
                    <td class="text-left">{{ x.first_name + ' ' + x.last_name }}</td>
                    <td class="text-left">{{ x.email }}</td>
                    <td class="text-left">{{ x.phone }}</td>
                    <td class="text-center table-actions">
                      <button class="btn btn-info btn-md mr-2" @click="handleResponderEdit(x)">Edit</button>
                      <!-- <button class="btn btn-primary mr-2" v-if="x.is_active == false" @click="activateResponder(1)">Activate</button>
                            <button class="btn btn-danger mr-2" v-else @click="activateResponder(0)">Deactivate</button> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions class="justify-end">
                <v-btn variant="tonal" @click="responderListDialog = false">Close</v-btn>
              </v-card-actions>
            </div>
          </v-card>

        </v-dialog>
      </v-col>
    </v-row>
    <v-row justify="space-around" v-if="notificationDialog == true">
      <v-col cols="auto">
        <v-dialog transition="dialog-bottom-transition" v-model="notificationDialog" persistent class="dialog">
          <v-card>
            <v-toolbar color="#007bff" title="Send Notification"></v-toolbar>
            <v-card-text>
              <v-form v-model="form">
                <v-text-field label="Title" variant="outlined" v-model="notificationData.title"
                  :rules="rules"></v-text-field>
                <v-text-field label="Message" variant="outlined" v-model="notificationData.message"
                  :rules="rules"></v-text-field>
                <v-select label="Select Campus" :item-title="'name'" :item-value="'id'" :items="campus" variant="outlined"
                  v-model="notificationData.campus_id" :rules="[requiredRules.required]" multiple clearable></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="notificationDialog = false; clearNotify()">Close</v-btn>
              <v-btn variant="tonal" background="#f80089" color="#f80089" :disabled="!form" @click="createNotification">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>


    </v-row>
    <div class="pagination mb-2">
      <div>
        <span>Page</span>
        <span>{{ currentPage }}</span>
        <span>of</span>
        <span>{{ pagination.length}}</span>
      </div>
      <div>
        <i disabled="currentPage === 1" @click="previousPage" style="cursor: pointer"><font-awesome-icon icon="chevron-left" /></i>
        <span>Previous</span>
        <div v-for="(n, i) in pagination" :key="i">
          <button class="btn" style="margin-right:0px" :class="{'btn-active': currentPage === n}" @click="changeCPage(n)">{{n}}</button>
        </div>
        <p v-if="pagination.length > 5" >...</p>
         <button class="btn " v-if="pagination.length > 5"
      :class="{ 'btn-inactive': currentPage === pagination.length }">{{ pagination.length }}</button>

        <span disabled="currentPage==pagination.length" @click="nextPage">Next</span>

        <i disabled="currentPage==pagination.length" @click="nextPage"><font-awesome-icon icon="chevron-right" style="cursor: pointer" /></i>
      </div>
    </div>
  </div>
</template>
<script setup>

import { ref, inject, onMounted } from 'vue';
// import { useUserStore } from '../stores'
import { makeGetRequest, makePostRequest, makePatchRequest } from '@/request';
import { toast } from 'vue3-toastify';
import is401 from '@/utils/is401';
// import axios from 'axios';

const swal = inject('$swal')
const campusDialog = ref(false)
const securityDialog = ref(false)
const responderDialog = ref(false)
const securityListDialog = ref(false)
const responderListDialog = ref(false)
const notificationDialog = ref(false)
// const file = ref(null)
const search = ref('')
const security = ref([])
const responder = ref([])
const formData = ref({
  name: ''
})
const notificationData = ref({
  title: '',
  campus_id: [],
  message: ''
})
const clearNotify = () => {
  notificationData.value.message = ''
  notificationData.value.title = ''
  notificationData.value.campus_id = []
}
const loading = ref(false)
const securityData = ref({
  first_name: '',
  last_name: '',
  campus_id: '',
  email: '',
  phone: '',
  identifier: 0
})
const clearSecurityForm = () => {
  securityData.value.email = ''
  securityData.value.first_name = ''
  securityData.value.last_name = ''
  securityData.value.phone = ''
  securityData.value.campus_id = ''
}
const edit = ref(false)
const campus = ref([])
const campusId = ref('')
const securityId = ref('')
const responderId = ref('')
const form = ref(false)

const pagination = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const recordsPerPage = pageSize.value
const lastIndex = currentPage.value * recordsPerPage

const previousPage = () => {
  if (currentPage.value !== 1) {
    currentPage.value = currentPage.value - 1
    getCampus()
  }
}
const changeCPage = (page) => {
        currentPage.value = page
}
const nextPage = () => {
  if (currentPage.value !== lastIndex && currentPage.value < pagination.value.length) {
    currentPage.value = currentPage.value + 1
    getCampus()
  }
}
const clearForm = () => {
  formData.value.name = ''
}
const rules = ref([
  (v) => !!v || 'Field is required',
  (v) => (v && v.length >= 3) || 'Field length must be at least 3 characters',
])
const requiredRules = ref({
  required: value => !!value || 'Field is required',
})
const emailRules = ref([
  (v) => !!v || 'E-mail is required',
  (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
],
)
const phoneRules = ref([
  (v) => !!v || 'Field is required',
  (v) => /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|909|908|911|912|913|914|915|916|917|919)([0-9]{7})$/.test(v) || 'Phone Number must be valid'
],
)
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: 'custom-swal-popup'// Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false
  });
}
const getCampus = () => {
  preLoader()
  loading.value = true
  let request = {
    what: 'GetAdminCampus',
    params: {
      search: ''
    }
  }
  if (search.value !== '') request.params.search = search.value.toLowerCase()
  makeGetRequest(request)
    .then(res => {
      campus.value = res.data.results;
      pagination.value = res.data.links.numbered
      loading.value = false
      swal.close()
    })
    .catch(error => {
      if (error.includes('logout and login')){
        is401(401)
      }
      swal.close()
      loading.value = false
      toast.error(error)
    })
}
const getSecurity = (id) => {
  campusId.value = id
  preLoader()
  let request = {
    what: 'GetSecurity',
    params: {
      search: '',
      campus_id: campusId.value
    }
  }
  if (search.value !== '') request.params.search = search.value.toLowerCase()
  makeGetRequest(request)
    .then(res => {
      security.value = res.data.hos.results;
      securityListDialog.value = true
      swal.close()
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const getResponder = (id) => {
  campusId.value = id
  preLoader()
  let request = {
    what: 'GetResponder',
    params: {
      search: '',
      campus_id: campusId.value
    }
  }
  if (search.value !== '') request.params.search = search.value.toLowerCase()
  makeGetRequest(request)
    .then(res => {
      responder.value = res.data.hos.results;
      responderListDialog.value = true
      swal.close()
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    })
}
const createCampus = () => {
  if (!form.value) return
  preLoader()
  campusDialog.value = false

  let request = {
    what: 'CreateAdminCampus',
    data: formData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearForm()
        swal.close()
        getCampus()
      }
      else {
        clearForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearForm()
      swal.close()
      toast.error(error)
    })

}
const createSecurity = () => {
  if (!form.value) return
  preLoader()
  securityDialog.value = false

  let request = {
    what: 'CreateSecurity',
    data: securityData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearSecurityForm()
        swal.close()
        getCampus()
      }
      else {
        clearSecurityForm()
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearSecurityForm()
      swal.close()
      toast.error(error)
    })

}
const createResponder = () => {
  if (!form.value) return
  preLoader()
  responderDialog.value = false

  let request = {
    what: 'CreateResponder',
    data: securityData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearSecurityForm()
        swal.close()
        getCampus()
      }
      else {
        clearSecurityForm()
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearSecurityForm()
      swal.close()
      toast.error(error)
    })

}
const handleCampusEdit = (campus) => {
  formData.value.name = campus.name
  campusId.value = campus.id
  edit.value = true
  campusDialog.value = true
}
const handleSecurityEdit = (security) => {
  securityData.value.first_name = security.first_name
  securityData.value.last_name = security.last_name
  securityData.value.email = security.email
  securityData.value.phone = security.phone
  securityId.value = security.id
  edit.value = true
  securityListDialog.value = false
  securityDialog.value = true
}
const handleResponderEdit = (responder) => {
  securityData.value.first_name = responder.first_name
  securityData.value.last_name = responder.last_name
  securityData.value.email = responder.email
  securityData.value.phone = responder.phone
  responderId.value = responder.id
  edit.value = true
  responderListDialog.value = false
  responderDialog.value = true
}
const editCampus = () => {
  if (!form.value) return
  preLoader()
  campusDialog.value = false
  let request = {
    what: 'EditAdminCampus',
    data: formData.value,
    id: campusId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearForm()
        swal.close()
        getCampus()
      }
      else {
        clearForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearForm()
      swal.close()
      toast.error(error)
    })
}
const editSecurity = () => {
  if (!form.value) return
  preLoader()
  securityDialog.value = false
  let request = {
    what: 'EditSecurity',
    data: securityData.value,
    id: securityId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearSecurityForm()
        swal.close()
        getCampus()
      }
      else {
        clearSecurityForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearSecurityForm()
      swal.close()
      toast.error(error)
    })
}
const editResponder = () => {
  if (!form.value) return
  preLoader()
  responderDialog.value = false
  let request = {
    what: 'EditResponder',
    data: securityData.value,
    id: responderId.value
  }
  makePatchRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearSecurityForm()
        swal.close()
        getCampus()
      }
      else {
        clearSecurityForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearSecurityForm()
      swal.close()

      toast.error(error)
    })
}
const activate = (status, id) => {
  preLoader()
  let request = {
    what: 'EditAdminCampus',
    id: id,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        toast.success(res.msg)
        getCampus()
        swal.close()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
const activateSecurity = (status) => {
  securityListDialog.value = false
  preLoader()
  let request = {
    what: 'EditSecurity',
    id: campusId.value,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        toast.success(res.msg)
        getCampus()
        swal.close()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
const activateResponder = (status) => {
  responderListDialog.value = false
  preLoader()
  let request = {
    what: 'EditResponder',
    id: campusId.value,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
        toast.success(res.msg)
        getCampus()
        swal.close()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
const createNotification = () => {
  if (!form.value) return
  preLoader()
  notificationDialog.value = false
  let notifyArray = notificationData.value.campus_id
  notificationData.value.campus_id = notifyArray.join(', ')
  let request = {
    what: 'CreateNotification',
    data: notificationData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearNotify()
        swal.close()
        getCampus()
      }
      else {
        clearForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearForm()
      swal.close()
      toast.error(error)
    })
}
// const activate = (id, status) =>{

// swal.showLoading()
// let request = {
//   what: 'ActivateInstitution',
//   id: id,
//   data: {
//     active: status
//   }
// }
// makePatchRequest(request)
// .then((res) => {
//   if (res.status == true) {
//     swal({
//       title: 'Successful',
//       text: res.msg,
//       icon: 'success',
//     }).then((result) => {
//       if (result.value) {
//         formData.value.name = ''
//         formData.value.institution_id = ''
//         getInstitution()
//         swal.close()
//       }
//     })
//   }
//   else {
//     swal.fire("Error", res.msg, "error");
//   }
// })
// .catch((error) => {
//   swal.fire("Error", error, "error");
// })
// }
onMounted(() => {
  getCampus()
})
</script>
<script>
export default {
  name: 'CampusPage',
}
</script>
<style scoped>
.main-wrapper {
  width: 100%;
}

.main-wrapper button {
  margin: 0;
}

.modal {
  align-items: center;
  background: rgba(0, 0, 0, .486);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 89%;
  margin: 0 auto;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-content {
  background: #fff;
  padding-bottom: 20px;
  border-radius: 4px;
}

.complaints-text {
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  width: clamp(35vw, 480px, 95vw);
}

.icon-cancel {
  cursor: pointer;
}

nav {
  background: transparent !important;
}

.pagination {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination div {
  display: flex;
  align-items: center;
  gap: 10px;

}

.pagination div span,
.pagination div i {
  font-size: 14px;
}

@media screen and (max-width: 720px) {
  .pagination {
    flex-direction: column;
    gap: 15px;
  }
}
</style>