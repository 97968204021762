<template>
    <div>
        <main>
            <div class="container customer-container">
                <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%">
                    <img src="../../public/harlequin.svg" alt="">
                        <h3 class="text-h6 text-uppercase">Emergency Notification Service</h3>
                        <h3 class="text-h6 mb-4 text-center text-uppercase">Account Verification</h3>

                    <div class="text-body-2">
                        We sent a verification code to {{ formData.email }} <br>

                        Please check your email and paste the code below.
                    </div>

                    <v-sheet color="surface">
                        <v-form v-model="form" ref="formRef">
                            <v-otp-input v-model="formData.otp_code" type="number" variant="solo"></v-otp-input>
                            <span style="color:red">{{error}}</span>
                        </v-form>
                    </v-sheet>

                    <v-btn class="my-4" color="#e83e8c" height="40" text="Verify" variant="flat" width="70%"
                        @click="SignIn"></v-btn>

                    <div class="text-caption">
                        Didn't receive the code? <a href="#" @click.prevent="newOTP">Resend</a>
                    </div>
                </v-card>
            </div>

        </main>
    </div>
</template>
<script setup>
import { VOtpInput } from 'vuetify/labs/VOtpInput'
import { ref, inject, onMounted } from 'vue'
import { makePostRequest } from '@/request';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import { useUserStore } from '@/stores'
const swal = inject('$swal')
const user = useUserStore()
const router = useRouter()
const error = ref('')
const userData = ref({
    email: user.email,
    password: user.password,
})


const formData = ref({
    email: user.email,
    password: user.password,
    otp_code: ''
})
const form = ref(false)
const formRef = ref(null)
const validateOtpForm = () =>{
    if (formData.value.otp_code == '') {
        error.value = 'OTP is required'
        form.value = false
    }
    else if (formData.value.otp_code.length !== 6 ){
        error.value = 'OTP length must be 6 Numbers'
        form.value = false
    }
    else if (formData.value.otp_code.length == 6){
        form.value = true
        error.value = ''
    }
    
}
const newOTP = () => {
  var request = {
    what: "UserAuthentication",
    data: userData.value
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        toast.success(res.msg)
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
const clearForm = () => {
    formData.value.email = ''
    formData.value.password = ''
    formData.value.otp_code = ''
}
const preLoader = () => { 
      swal.fire({title: "", 
      customClass: {
          htmlContainer: 'custom-swal-popup'// Add your custom class here
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
}
const SignIn = () => {
    
    validateOtpForm()
    if (!form.value) return
    preLoader()
    const user = useUserStore()
    var request = {
        what: "OtpVerify",
        data: formData.value
    };
    makePostRequest(request)
        .then(res => {
            if (res.status == true) {
                user.setLogin(true)
                user.setToken(res.data.token)
                user.setRole(res.data.role)
                user.setName(res.data.name)
                user.setUserID(res.data.id)
                if (res.data.institution_id){
                    user.setInstitutionID(res.data.institution_id)
                }
                if (res.data.campus_id){
                    user.setCampusID(res.data.campus_id)
                }
                toast.success(res.msg);
                clearForm()
                swal('Login Successful', res.msg, 'success')
                router.push('/dashboard')
                // if (user.role == 'Institution Admin'){
                //     router.push('/campus')
                // }
                // if (user.role == 'System Admin'){
                //     router.push('/institution')
                // }
                // if (user.role == 'Head of Security'){
                //     router.push('/responder')
                // }
                // if (user.role == 'Security Agent'){
                //     router.push('/report')
                // }
                


            }
            else {
                toast.error(res.msg);
            }
        })
        .catch(error => {
            formData.value.otp_code = ''
            swal.close()
            toast.error(error)
        });

}
onMounted(() =>{
    console.log(user.email, 'email')
    if (user.email == ''){
        router.push('/')
        localStorage.clear('useUserStore')
    }
})
</script>
<script>

export default {
    name: 'UserVerification',
}
</script>
<style></style>