import { useUserStore } from "./stores"
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

export const makeGetRequest = (request) => {
    const request_urls = {
        'GetInstitution': 'institution/',
        'GetCampus': 'institution/campus/',
        'GetAdmin': 'institution/admin/',
        'GetAdminCampus': 'institution/campus/ia/',
        'GetSecurity': 'institution/user/hos/',
        'GetResponder': 'institution/user/sa/',
        'GetSecurityResponder': 'institution/hos/user/sa/',
        'GetReport': 'institution/view-reports/',
        'ViewProfile': 'web/auth/profile/',
        'GetStudents': 'institution/students-staff/',
        'GetAnalytics': 'institution/analytics/',
        "GetReportHistory": 'institution/view-raised-alerts/'
    }    
    const user = useUserStore()
    const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    } else {
        request_url = request_urls[request.what]
    }
    
    return new Promise((resolve, reject) => { 
    axios.get(request_url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {        
            if (response.data.status) {
                var res = {
                    data: response.data.data,
                    type: request.what,
                    success: true
                }                
                resolve(res)
            } else {
                reject(response.data)
            }
        })
        .catch((err) => {
            if(err.response) reject(err.response.data.msg)
            else reject("Network Error. Reload the page or check your internet connection")
        })
    })
}

export const makePostRequest = (request)  => {
    const request_urls = {
        'UserAuthentication': 'web/auth/login/',
        'OtpVerify': 'web/auth/login/',
        'CreateInstitution': 'institution/',
        'CreateCampus': 'institution/campus/',
        'CreateAdmin': 'institution/admin/',
        'CreateAdminCampus': 'institution/campus/ia/',
        'CreateSecurity': 'institution/user/hos/',
        'CreateResponder': 'institution/user/sa/',
        'CreateSecurityResponder': 'institution/hos/user/sa/',
        'InitiateRequest': 'web/auth/password-reset/',
        'ValidateToken': 'web/auth/password-reset/validate-token/',
        'ResetPassword': 'web/auth/password-reset/confirm/',
        'UpdateProfile': 'web/auth/profile/',
        'UpdatePassword': 'web/auth/password/',
        'CreateNotification': 'institution/notify/',
        'CreateStudent': 'institution/students-staff/'
    }
        const user = useUserStore()
        const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config;
    if(request_url !== 'web/auth/login/'){ // do not set access token if request is pointing to 'authenticate'
        config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    }   
    return new Promise((resolve, reject) => {
    axios.post(request_url, data, config)
            .then((response) => {
                var res;
                if (response.status) {
                    res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                        data: response.data.data
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}

export const makePatchRequest = (request)  => {

    const request_urls = {
        'EditInstitution': '/institution/' + request.id + '/',
        'EditCampus': '/institution/campus/' + request.id + '/',
        'EditAdmin': '/institution/admin/' + request.id + '/',
        'ActivateInstitution': 'institution/'  + request.id + '/',
        'ActivateAdmin': '/institution/admin/' + request.id + '/',
        'EditAdminCampus': '/institution/campus/ia/' + request.id + '/',
        'EditSecurity': '/institution/user/hos/' + request.id + '/',
        'EditResponder': '/institution/user/sa/' + request.id + '/',
        'EditSecurityResponder': '/institution/hos/user/sa/'  + request.id + '/',
        'ActivateResponder': '/institution/hos/user/sa/'  + request.id + '/',
        'EditReport': 'institution/view-raised-alerts/'
    }
    const user = useUserStore()
        const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config = {
        headers: {
            'Authorization': 'Bearer ' + token 
        }
    }
    return new Promise((resolve, reject) => {
    axios.patch(request_url, data, config)
            .then((response) => {
                if (response.data.status) {                    
                    var res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}

export const makePutRequest = (request)  => {

    const request_urls = {
        'ActivateInstitution': 'institution/'  + request.id + '/',
        'EditReport': 'institution/view-raised-alerts/',
    }
    const user = useUserStore()
    const token = user.token
    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) => { return key + '=' + obj[key] }).join('&')
        var request_url = request_urls[request.what] + "?" + params
    }else{
        request_url = request_urls[request.what]
    }

    var data = (request.data !== undefined) ? request.data : {}
    var config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    return new Promise((resolve, reject) => {
    axios.put(request_url, data, config)
            .then((response) => {
                if (response.data.status) {                    
                    var res = {
                        msg: response.data.msg,
                        type: request.what,
                        status: true,
                    }
                    resolve(res)
                } else {
                    reject(response.data)
                }
            })
            .catch((err) => {
                if(err.response) reject(err.response.data.msg)
                else reject("Network Error. Reload the page or check your internet connection")
            })
    })
}

