
import  { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import "./plugins/vuetify"
import "./assets/styles.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faFacebook, faInstagram, faYoutube,faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faMessage, faSearch, faPhone, faPowerOff, faChevronDown, faUser, faTimes, faChevronRight, faChevronLeft, faTag, faInstitution, faBell, faEllipsis, faRightFromBracket, faBars,faArrowLeft, faLocationDot, faChalkboard, faClockRotateLeft} from '@fortawesome/free-solid-svg-icons'
library.add(faTwitter, faFacebook, faInstagram, faYoutube, faMessage, faSearch, faPhone, faPowerOff, faChevronDown, faUser, faTimes, faChevronLeft, faChevronRight,  faTag, faInstitution, faLocationDot, faBell,faChalkboard, faXTwitter, faEllipsis, faRightFromBracket, faBars, faArrowLeft, faClockRotateLeft )

import {useUserStore} from './stores'
import axios from 'axios';
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Toasity, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia';
// import firebase from "firebase";

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import { VueFire, VueFireAuth } from 'vuefire'
import { fbApp } from './init'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
  components,
  directives,
})
import { VOtpInput } from 'vuetify/labs/VOtpInput'
import VueGoogleMaps from '@fawmi/vue-google-maps';

import {gmapApi} from 'vue3-google-maps'


export default createVuetify({
  components: {
    VOtpInput,
     },
})
router.beforeEach((to, from, next) => {
  const user = useUserStore()
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = user.isLoggedIn

  if (from.path) {
    localStorage.setItem('previousRoute', from.fullPath);
  }
  if ((!isPublic && !loggedIn)) {
    return next({
        path: "/",
        // query: { redirect: to.fullPath }
        // Store the full path to redirect the user to after login
    });
  }
  if (loggedIn && onlyWhenLoggedOut) {
    if (isPublic && user.role === 'System Admin') {
        return next("/institution");
    }
    if (isPublic && user.role === 'Institution Admin') {
      return next("/campus");
  }
  if (isPublic && user.role === 'Head of Security') {
    return next("/responder");
  }
  if (isPublic && user.role === 'Security Agent') {
    return next("/report");
  }
    return next("/report");
  }
  if (to.meta.requiresAuth) {
      if (user.isLoggedIn) {
        if (to.path === '/' || to.path === '/verify') {
          if (user.role === 'System Admin'){
            next('/institution')
          }
          if (user.role === 'Institution Admin'){
            next('/campus')
          }
          if (user.role === 'Head of Security'){
            next('/responder')
          }
          if (user.role === 'Security Agent'){
            next('/report')
          }
        }
          next()
          return
      }
      next('/')
  } else {
      next()
  }
})

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueAxios, axios)
app.use(Vue3Toasity, {
  autoClose: 3000,
  position: toast.POSITION.TOP_RIGHT
});
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWugqPI9pOfAENPd4B-DmApRfBtNkDWbo',
}}),
app.use(VueFire, {
  // imported above but could also just be created here
  fbApp,
  
})
app.use(pinia)
app.use(router).use(vuetify).use(VueSweetalert2).mount('#app')
