<template>
    <nav>
        <div class="nav-container">
            <img src="../../public/harlequin.svg" alt="">
            <!-- <ul v-for="(node, index) in nodes" :key="`node-${index}`">
                <router-link v-if="!node.heading && !node.sub && shouldDisplay(node, role)" :to="node.to || '#'" class="nav-inactive"
                    :active-class="(node.to && node.to !== '#' && node.to !== '/') ? 'nav-active' : 'nav-inactive'">
                    <li>
                        <i><font-awesome-icon :icon="`fa-solid ${node.icon}`" size="lg" /></i>{{ node.name}}
                    </li>
                </router-link>
            </ul> -->
            <ul>
                <router-link to="/dashboard" class="nav-inactive" 
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-chalkboard" size="lg" /></i>Dashboard
                </li>
            </router-link>
            <router-link to="/institution" class="nav-inactive" v-if="user.role=='System Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-institution" size="lg" /></i>Institutions
                </li>
            </router-link>
            <router-link to="/campus" class="nav-inactive" v-if="user.role=='Institution Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-institution" size="lg" /></i>Campuses
                </li>
            </router-link>
            <router-link to="/students" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='System Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-user" size="lg" /></i>Students / Staffs
                </li>
            </router-link>
            <router-link to="/responder" class="nav-inactive" v-if="user.role=='Head of Security'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-message"  size="lg" /></i>Responders
                </li>
            </router-link>
            <router-link to="/report" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='Security Agent'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-tag"  size="lg" /></i>Reports
                </li>
            </router-link>
            <router-link to="/alert-history" class="nav-inactive" v-if="user.role=='Institution Admin' || user.role=='Security Agent'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-clock-rotate-left"  size="lg" /></i>History
                </li>
            </router-link>
            <router-link to="/map" class="nav-inactive" v-if="user.role=='Institution Admin'"
                :active-class="'nav-active'">
                <li>
                    <i><font-awesome-icon icon="fa-solid fa-location-dot" size="lg" /></i>Global Maps
                </li>
            </router-link>
        </ul>
        </div>
    </nav>
</template>
<script setup>
// import { ref } from 'vue'
import menuList from '@/data/menu'
import { useUserStore } from '@/stores'


// const show = ref(false)
const user = useUserStore()
const role = user.role
const nodes = menuList.main

const shouldDisplay = (node, role) => {
      // Check if the userRole is included in the node's role array
      return Array.isArray(node.role) ? node.role.includes(role) : node.role === role;
    
}
</script>
<script>

export default {
    name: 'SideLayout',
}
</script>