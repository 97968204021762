<template>
   <div id="map" style="width: auto; height: 100vh">
    <div class="bread-crumb__container shadow pa-3">
      <i class="pointers" title="Click to Go to Alert List" @click="goToPreviousRoute"><font-awesome-icon icon="fa-solid fa-arrow-left" /></i>
      <p class="text-capitalize">Global Map</p>
    </div>
    <GMapMap :center="center"
        :zoom="7"
        style="width: auto; height: 100vh;"
        map-type-id="terrain"
  >
      <GMapMarker
        :key="index"
        v-for="(m, index) in paths"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="openMarker(m.position.id);openLocation(m.position.id)"
      >
      <GMapInfoWindow
          :closeclick="true"
          @closeclick="openMarker(null)"
          :opened="openedMarkerID === m.position.id"
      >
        <div v-if="m.position.role && m.position.name"> {{  m.position.name }} ({{m.position.role}}) 
        </div>
        <div v-else> User 
        </div>
      </GMapInfoWindow>
    </GMapMarker>
    </GMapMap>
   </div>
  </template>
  <script setup>
  import {ref, onMounted, watch} from 'vue'
  import {fbApp} from '../init'
  import { useRouter } from 'vue-router'
  import { toast, } from 'vue3-toastify';

  const router = useRouter()
  
  const openedMarkerID = ref(null)
  const db = fbApp.firestore()
  const paths = ref([]);
  const path = ref([])
  const center = ref({})
const readDoc = () =>{
  let employeesData = [];
      db.collection("user_locations")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            paths.value.push({
              position:{
                id: doc.id,
                lat: doc.data().latitude,
                lng: doc.data().longitude,
                name: doc.data().name,
                role: doc.data().role
              }
            })
           employeesData.push({
              position:{
                latitude: doc.data().latitude,
                longitude: doc.data().longitude,
              }
              
            });
          });
          const isSupported = 'navigator' in window && 'geolocation' in navigator
          if (isSupported){
            navigator.geolocation.getCurrentPosition((position) => {
            center.value = { lat: position.coords.latitude, lng: position.coords.longitude }
          });
          }
          return paths.value, center.value
        })
        .catch((error) => {
          toast.error("Error getting documents");
        });
}
const openMarker = (id) => {
  openedMarkerID.value = id
}

const goToPreviousRoute = () => {
            const previousRoute = localStorage.getItem('previousRoute');
            if (previousRoute) {
                router.push(previousRoute);
            } else {
                router.push('/');
            }
        };
const openLocation = (id) =>{
  setTimeout(() =>{
    router.push('/location/' + id)
  }, 1000)
}
onMounted(() =>{
  readDoc()
})
  </script>
  <script>
  export default {
    name: 'GlobalMap',
   
  }
  </script>