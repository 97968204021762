<template>
  <div>
    <main>
      <div class="container customer-container">
        <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%"
          v-if="validate == null">
          <img src="../../public/harlequin.svg" alt="">
          <h3 class="text-h6 text-uppercase">Emergency Notification Service</h3>
          <h3 class="text-h6 text-center text-uppercase">Forgot Password</h3>
          <p class="mb-4">Enter your registered Email</p>
          <v-sheet color="surface">
            <v-form v-model="form">
              <v-text-field label="Email" variant="outlined" v-model="formData.email" type="email" required
                :rules="emailRules"></v-text-field>
            </v-form>
          </v-sheet>

          <v-btn class="my-4" color="#e83e8c" height="40" text="Submit" variant="flat" width="70%" :disabled="!form"
            type="submit" @click.prevent="SignIn" v-if="!validate"></v-btn>
          <v-btn class="my-4" color="#e83e8c" height="40" text="Validate Token" variant="flat" width="70%"
            :disabled="!form" type="submit" @click.prevent="SignIn" v-else></v-btn>
        </v-card>
        <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%"
          v-if="validate == false">
          <h3 class="text-h6 mb-1">Validate</h3>
          <p class="mb-4">Enter Token sent to your email</p>
          <v-sheet color="surface">
            <v-form v-model="form">
              <v-text-field label="Token" variant="outlined" v-model="formData.token" type="text" required
                :rules="[rules.required]"></v-text-field>
            </v-form>
          </v-sheet>

          <v-btn class="my-4" color="#e83e8c" height="40" text="Validate Token" variant="flat" width="70%"
            :disabled="!form" type="submit" @click.prevent="ValidateToken"></v-btn>
        </v-card>
        <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%"
          v-if="validate == true">
          <h3 class="text-h6 mb-1">New Password</h3>
          <p class="mb-4">Enter Your New Password</p>
          <v-sheet color="surface">
            <v-form v-model="form">
              <v-text-field label="New Password" variant="outlined" v-model="formData.password" type="password" required
                :rules="[rules.required]"></v-text-field>
            </v-form>
          </v-sheet>
          <v-btn class="my-4" color="#e83e8c" height="40" text="Submit" variant="flat" width="70%" :disabled="!form"
            type="submit" @click.prevent="submitPassword"></v-btn>
        </v-card>
      </div>

    </main>
  </div>
</template>
<script setup>


// export default createVuetify ({
//   components: {
//     VOtpInput,
//      },
// })
import { ref, inject } from 'vue'
import { makePostRequest } from '@/request';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
const swal = inject('$swal')

const router = useRouter()
const validate = ref(null)
const formData = ref({
  email: '',
  token: '',
  password: ''
})
const form = ref(false)
const rules = ref({
  required: value => !!value || 'Field is required',
})
const emailRules = ref([
  (v) => !!v || 'E-mail is required',
  (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
],
)
const clearForm = () => {
  formData.value.email = ''
}
const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: 'custom-swal-popup',
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false
  });
}
const SignIn = () => {
  if (!form.value) return
  preLoader()
  var request = {
    what: "InitiateRequest",
    data: {
      email: formData.value.email
    }
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        // user.setEmail(formData.value.email)
        // user.setPassword(formData.value.password)

        toast.success(res.msg)
        validate.value = false
        // clearForm()
        // resetForm()
        swal.close()
        // router.push('/verify')
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
const ValidateToken = () => {
  if (!form.value) return
  preLoader()
  var request = {
    what: "ValidateToken",
    data: {
      email: formData.value.email,
      token: formData.value.token
    }
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        // user.setEmail(formData.value.email)
        // user.setPassword(formData.value.password)

        toast.success(res.msg)
        validate.value = true
        // clearForm()
        // resetForm()
        swal.close()
        // router.push('/verify')
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
const submitPassword = () => {
  if (!form.value) return
  preLoader()
  var request = {
    what: "ResetPassword",
    data: formData.value
  };
  makePostRequest(request)
    .then(res => {
      if (res.status == true) {
        toast.success(res.msg)
        swal({
          title: 'Successful',
          text: res.msg,
          icon: 'success',
        }).then((result) => {
          router.push('/')
        })
        
      }
      else {
        toast.success(res.msg);
      }
    })
    .catch(error => {
      swal.close()
      toast.error(error)
    });

}
</script>
<script>

export default {
  name: 'ForgotPassword',
}
</script>
<style scoped></style>