<template>
    <div class="main-wrapper" style="background-color: #fff">
      <div class="search-bar">
        <input
          type="search"
          name=""
          id=""
          placeholder="Search"
          v-model="search"
        />
        <!-- <div>
          <select name="" id="">
            <option value="treated"></option>
            <option value="treated"></option>
          </select>
        </div> -->
        <!-- <button @click="dialog=true">Create Report</button> -->
      </div>
      <div class="complaint-table">
        <v-table class="table">
          <thead class="table-header__color">
            <tr>
              <th class="text-center text-uppercase font-weight-bold">S/N</th>
              <th class="text-center text-uppercase font-weight-bold">
                Latitude
              </th>
              <th class="text-center text-uppercase font-weight-bold">
                Longitude
              </th>
              <th class="text-center text-uppercase font-weight-bold">
                Situation
              </th>
              <th class="text-center text-uppercase font-weight-bold">
                Date Created
              </th>
              <th class="text-center text-uppercase font-weight-bold">
                Date Treated
              </th>
              <th class="text-center text-uppercase font-weight-bold">Status</th>
              <th class="text-center text-uppercase font-weight-bold">
                Alarm Type
              </th>
              <!-- <th class="text-center text-uppercase font-weight-bold">
                        Campus
                      </th> 
                      <th class="text-center text-uppercase font-weight-bold">
                        University
                      </th> -->
              <!-- <th class="text-center text-uppercase font-weight-bold">
                        Date
                      </th> -->
              <th class="text-center text-uppercase font-weight-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading && report.length < 1" class="text-center">
              <td colspan="8">Loading...</td>
            </tr>
            <tr v-if="!loading && report.length < 1" class="text-center">
              <td colspan="8">No Record at the Moment</td>
            </tr>
            <tr v-else v-for="(alert, index) in report" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
  
              <td class="text-center">{{ alert.latitude  }}</td>
              <td class="text-center">{{ alert.longitude }}</td>
              <td class="text-center">{{ alert.caption }}</td>
              <td class="text-center">{{ formatDate(alert.date_created) }}</td>
              <td class="text-center">{{ formatDate(alert.date_updated) }}</td>
  
              <td class="text-center">{{ alert.is_read == true ? "Treated" : "Not Treated"}}</td>
              <td class="text-center">{{ alert.is_false == true ? "False Alarm" : "True Alarm"}}</td>
              <td class="text-center">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <font-awesome-icon
                      icon="fa-solid fa-ellipsis"
                      v-bind="props"
                      size="xl"
                      class=""
                    />
                  </template>
  
                  <v-list>
                    <v-list-item>
                      <router-link
                        :to="{ path: 'location/' + alert.user.id }"
                        style="text-decoration: none"
                        ><button class="btn btn-primary">
                          View on Map
                        </button></router-link
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
      <!-- <div class="pagination">
        <div>
          <span>Page</span>
          <span>1</span>
          <span>of</span>
          <span>10</span> <span>-</span>
          <span>20</span>
        </div>
        <div>
          <i><font-awesome-icon icon="chevron-left" /></i>
          <span>Previous</span>
          <button class="btn btn-lg">1</button>
          <button class="btn btn-inactive">2</button>
          <button class="btn btn-inactive">3</button>
          <span>Next</span>
  
          <i><font-awesome-icon icon="chevron-right" /></i>
        </div>
      </div> -->
    </div>
  </template>
  <script setup>
  import { ref, inject, onMounted } from "vue";
  import { makeGetRequest} from "@/request";
  import { toast } from "vue3-toastify";
  import { useUserStore } from "@/stores";
  const user = useUserStore();
  
  
  // const alerts = useCollection(collection(db, 'emergency_alerts'))
  const swal = inject("$swal");
  const loading = ref(false);
  const search = ref("");
  const report = ref([]);
  const filteredResult = ref([]);

  const formatDate = (original) => {
  const date = new Date(original);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};
  
  const preLoader = () => {
    swal.fire({
      title: "",
      customClass: {
        htmlContainer: "custom-swal-popup", // Add your custom class here
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>',
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  };
  const getReport = () => {
    preLoader();
    let request = {
      what: "GetReportHistory",
      params: {
        search: "",
      },
    };
    if (search.value !== "") request.params.search = search.value.toLowerCase();
    makeGetRequest(request)
      .then((res) => {
        report.value = res.data.results;
        filterTable()
        swal.close();
      })
      .catch((error) => {
        swal.close();
        toast.error(error);
      });
  };
  
 
  const filterTable = () => {
    report.value = report.value.filter((data) => {
      if (search.value == "") {
        return report.value;
      }
      if (data.alert_type.toLowerCase().includes(search.value.toLowerCase())) {
        return data.alert_type.toLowerCase().includes(search.value.toLowerCase());
      }
      if (data.type.toLowerCase().includes(search.value.toLowerCase())) {
        return data.type.toLowerCase().includes(search.value.toLowerCase());
      }
      if (data.is_false.toLowerCase().includes(search.value.toLowerCase())) {
        return data.is_false.toLowerCase().includes(search.value.toLowerCase());
      }
    });
  };
  
  
  onMounted(() => {
    getReport()
  });
  </script>
  <script>
  export default {
    name: "AlertHistory",
  };
  </script>
  <style scoped>
  .pagination {
    width: 97%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .pagination div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .pagination div span,
  .pagination div i {
    font-size: 14px;
  }
  
  @media screen and (max-width: 720px) {
    .pagination {
      flex-direction: column;
      gap: 15px;
    }
  }
  </style>
  