<template>
  <div class="main-wrapper" style="background-color: #fff">
    <div class="search-bar">
      <input
        type="search"
        name=""
        id=""
        placeholder="Search"
        v-model="search"
      />
      <!-- <div>
        <select name="" id="">
          <option value="treated"></option>
          <option value="treated"></option>
        </select>
      </div> -->
      <!-- <button @click="dialog=true">Create Report</button> -->
    </div>
    <div class="complaint-table">
      <v-table class="table">
        <thead class="table-header__color">
          <tr>
            <th class="text-center text-uppercase font-weight-bold">S/N</th>
            <th class="text-center text-uppercase font-weight-bold">
              Latitude
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Longitude
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Situation
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Raised By
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Phone
            </th>
            <th class="text-center text-uppercase font-weight-bold">
              Date Created
            </th>
            <th class="text-center text-uppercase font-weight-bold">Status</th>
            <th class="text-center text-uppercase font-weight-bold">
              Alarm Type
            </th>
            <!-- <th class="text-center text-uppercase font-weight-bold">
                      Campus
                    </th> 
                    <th class="text-center text-uppercase font-weight-bold">
                      University
                    </th> -->
            <!-- <th class="text-center text-uppercase font-weight-bold">
                      Date
                    </th> -->
            <th class="text-center text-uppercase font-weight-bold">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading && report.length < 1" class="text-center">
            <td colspan="10">Loading...</td>
          </tr>
          <tr v-if="!loading && report.length < 1" class="text-center">
            <td colspan="10">No Record at the Moment</td>
          </tr>
          <tr v-else v-for="(alert, index) in report" :key="index">
            <td class="text-center">{{ index + 1 }}</td>

            <td class="text-center">{{ alert.latitude }}</td>
            <td class="text-center">{{ alert.longitude }}</td>
            <td class="text-center">{{ alert.alert_type }}</td>
            <td class="text-center">{{ alert.name }}</td>
            <td class="text-center">{{ alert.phone }}</td>
            <td class="text-center">{{ alert.timestamp }}</td>
            

            <td class="text-center">{{ alert.type }}</td>
            <td class="text-center">{{ alert.is_false }}</td>
            <td class="text-center">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <font-awesome-icon
                    icon="fa-solid fa-ellipsis"
                    v-bind="props"
                    size="xl"
                    class=""
                  />
                </template>

                <v-list>
                  <v-list-item class="list-item-btn">
                    <!-- <v-list-item-title> -->
                    <button
                      class="btn btn-info mr-2"
                      @click="updateReport(alert.user_id, 'status')"
                    >
                      Mark as Read
                    </button>
                  </v-list-item>
                  <v-list-item class="list-item-btn">
                    <!-- <v-list-item-title> -->
                    <button
                      class="btn btn-info mr-2"
                      @click="updateReport(alert.user_id, 'alarm')"
                    >
                      Mark as False Alarm
                    </button>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      :to="{ path: 'location/' + alert.user_id }"
                      style="text-decoration: none"
                      ><button class="btn btn-primary">
                        View on Map
                      </button></router-link
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
    <!-- <div class="pagination">
      <div>
        <span>Page</span>
        <span>1</span>
        <span>of</span>
        <span>10</span> <span>-</span>
        <span>20</span>
      </div>
      <div>
        <i><font-awesome-icon icon="chevron-left" /></i>
        <span>Previous</span>
        <button class="btn btn-lg">1</button>
        <button class="btn btn-inactive">2</button>
        <button class="btn btn-inactive">3</button>
        <span>Next</span>

        <i><font-awesome-icon icon="chevron-right" /></i>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import { makeGetRequest, makePatchRequest, makePutRequest } from "@/request";
import { toast } from "vue3-toastify";
import { useCollection, useDocument } from "vuefire";
import {
  collection,
  getFirestore,
  doc,
  getDocs,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { fb } from "../firebase/init";
import { useUserStore } from "@/stores";
const user = useUserStore();

const db = getFirestore(fb);
// const alerts = useCollection(collection(db, 'emergency_alerts'))
const swal = inject("$swal");
const loading = ref(false);
const search = ref("");
const report = ref([]);
const filteredResult = ref([]);
const id = ref("");
let previousData = [];
const edit = ref(false);

const preLoader = () => {
  swal.fire({
    title: "",
    customClass: {
      htmlContainer: "custom-swal-popup", // Add your custom class here
    },
    html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
};
const getReport = () => {
  preLoader();
  let request = {
    what: "GetReport",
    params: {
      search: "",
    },
  };
  if (search.value !== "") request.params.search = search.value.toLowerCase();
  makeGetRequest(request)
    .then((res) => {
      report.value = res.data.results;
      swal.close();
    })
    .catch((error) => {
      swal.close();
      toast.error(error);
    });
};
// const fetchData = async () => {
//   loading.value = true
//   preLoader()
//   const alertsSnapshot = await getDocs(collection(db, 'emergency_alerts'));
//   if (alertsSnapshot) {
//     const alerts = [];

//     alertsSnapshot.forEach((doc) => {

//       const alert = doc.data();
//       // if (alert.user_id === user.userID) {
//         alert.id = doc.id
//         alerts.push(alert);

//       // }
//     });
//     report.value = alerts
//     loading.value = false
//     swal.close()
//   }
//   else {
//     loading.value = false
//     swal.close()
//     toast.error("Error, Data not available")
//   }

// };
const fetchData = async () => {
  loading.value = true;
  preLoader();
  try {
    const alertsSnapshot = await getAlertsSnapshot();
    processSnapshot(alertsSnapshot);

    loading.value = false;
    swal.close();
  } catch (error) {
    swal.close();
    toast.error("Error fetching initial data:", error.message);
    // Handle the error condition, show a toast, etc.
  } finally {
    swal.close();
    loading.value = false;
  }
};
const getAlertsSnapshot = async () => {
  const snapshot = await getDocs(
    collection(db, "emergency_alerts"),
    orderBy("timestamp", "desc")
  );
  return snapshot;
};
const processSnapshot = (snapshot) => {
  const alerts = [];
  snapshot.forEach((doc) => {
    const alert = doc.data();
    if (user.campusID || user.institutionID) {
      if (
        user.campusID == alert.campus_id ||
        user.institutionID == alert.institution_id
      ) {
        alert.id = doc.id;
        alert.timestamp = doc.data().timestamp.toDate().toLocaleString();
        alert.user_id = doc.data().user_id;
        alert.type = doc.data().is_treated == true ? "Treated" : "Not Treated";
        alert.name = doc.data().name
        alert.phone = doc.data().phone
        alert.is_false =
          doc.data().is_false_alert == true ? "False Alarm" : "True Alarm";
        alerts.push(alert);

        // filterTable()
      }
    }
  });
  alerts.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  // report.value = alerts
  if (!arraysAreEqual(previousData, alerts)) {
    report.value = alerts;
    if (alerts) {
      sendNotification();
    }
    previousData = alerts.slice(); // Update previousData
  }
};
const listenToAlerts = () => {
  const alertsCollection = collection(db, "emergency_alerts");
  const unsubscribe = onSnapshot(alertsCollection, (snapshot) => {
    processSnapshot(snapshot);
  });

  return unsubscribe;
};
const sendNotification = () => {
  console.log('edit.value', edit.value)
  if (Notification.permission === "granted" && !edit.value) {
    new Notification("New Emergency Alert", {
      body: "You just got a new alert. kindly check it out.",
    });
  } else if (Notification.permission !== "denied" && !edit.value) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted" && edit.value == false) {
        new Notification("New Emergency Alert", {
          body: "You just got a new alert. kindly check it out.",
        });
      }
    });
  }
};
const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }
  return true;
};
// const filterTable = () => {
//   report.value = report.value.filter((data) => {
//     if (search.value == "") {
//       return report.value;
//     }
//     if (data.alert_type.toLowerCase().includes(search.value.toLowerCase())) {
//       return data.alert_type.toLowerCase().includes(search.value.toLowerCase());
//     }
//     if (data.type.toLowerCase().includes(search.value.toLowerCase())) {
//       return data.type.toLowerCase().includes(search.value.toLowerCase());
//     }
//     if (data.is_false.toLowerCase().includes(search.value.toLowerCase())) {
//       return data.is_false.toLowerCase().includes(search.value.toLowerCase());
//     }
//   });
// };

// const updateAlert = (id, status, type) => {
//   preLoader();
//   edit.value = true;
//   const docRef = doc(db, "emergency_alerts", id);
//   let data = {};
//   if (type == "set_status") {
//     data = {
//       is_treated: status,
//     };
//   } else {
//     data = {
//       is_false_alert: status,
//     };
//   }

//   updateDoc(docRef, data)
//     .then((docRef) => {
//       if (type == "set_status") {
//         toast.success("Status Updated!");
//       } else {
//         toast.success("Alarm type updated!");
//       }
//       edit.value = false;
//       swal.close();
//       // fetchData()
//     })
//     .catch((error) => {
//       toast.error("Update Error");
//     });
// };
// const getData = () => {
//   loading.value = true;
//   preLoader();
//   const alerts = [];
//   const docRef = getDocs(collection(db, "emergency_alerts"));
//   onSnapshot(docRef, (alertSnapShot) => {
//     alertSnapShot.forEach((doc) => {
//       const alert = doc.data();
//       // if (alert.user_id === user.userID) {
//       alert.id = doc.id;
//       alerts.push(alert);
//     });
//   });

//   report.value = alerts;
//   loading.value = false;
//   swal.close();
// };
const updateReport = (id, type) => {
  preLoader();
  edit.value = true;
  const data = {
    user_id: id,
  };

  let request = {
    what: "EditReport",
    data: data,
  };
  if (type == "status") {
    makePatchRequest(request)
      .then((res) => {
        if (res.status) {
          toast.success(res.msg);
          swal.close();
        }
      })
      .catch((error) => {
        toast.error(error);
        swal.close();
      });
  } else {
    makePutRequest(request)
      .then((res) => {
        if (res.status) {
          toast.success(res.msg);
          swal.close();
        }
      })
      .catch((error) => {
        toast.error(error);
        swal.close();
      });
  }
};
onMounted(() => {
  fetchData();
  listenToAlerts();
});
</script>
<script>
export default {
  name: "ReportPage",
};
</script>
<style scoped>
.pagination {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination div span,
.pagination div i {
  font-size: 14px;
}

@media screen and (max-width: 720px) {
  .pagination {
    flex-direction: column;
    gap: 15px;
  }
}
</style>
