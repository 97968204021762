import { defineStore } from 'pinia'

// Helpers
const helpers = {
    
}

export const useUserStore = defineStore('UserStore',  {
    state: () => ({
        isLoggedIn: '',
        email:'',
        password: '',
        role: '',
        token: '',
        name: '',
        userID: '',
        campusID: '', 
        institutionID: '',
        // showMobileMenu: false,
        app: {
            name: 'ensng portal',
            version: process.env.PACKAGE_VERSION,
            env: process.env.VUE_APP_ENVIRONMENT,
            // copyright: helpers.getCurrentYear()
        },
        
    }),
    actions: {
        setToken(data){
            this.token = data
        },
        setEmail(data){
            this.email = data
        },
        setLogin(data){
            this.isLoggedIn = data
        },
        setRole(data){
            this.role = data
        },
        setPassword(data){
            this.password = data
        },
        setName(data){
            this.name = data
        },
        setUserID(data){
            this.userID = data
        },
        setCampusID(data){
            this.campusID = data
        },
        setInstitutionID(data){
            this.institutionID = data
        }
        // setMobileMenu(){
        //     this.showMobileMenu = !this.showMobileMenu
        // }
    },
    // getters: {
    //     showMobileMenu: (state) => state.showMobileMenu,
    //   },
    persist: true
})