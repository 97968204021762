<template>
    <div class="main-wrapper" style="background-color: #fff;">
        
        <div class="search-bar">
            <input type="search" name="" id="" placeholder="Search" v-model="search">
            <div class="d-flex">
              <button class="mr-2" @click="notificationDialog=true"><font-awesome-icon icon="fa-solid fa-bell" size="lg"/></button>
              <button @click="responderDialog=true">Create Responder</button>
          </div>
        </div>
        <div class="complaint-table">
          <v-table class="table" hover="true">
            <thead class="table-header__color">
              <tr>
                <th class="text-left text-uppercase font-weight-bold">
                  S/N
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Name
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Email
                </th>
                <th class="text-left text-uppercase font-weight-bold">
                  Phone
                </th>
                <th class="text-center text-uppercase font-weight-bold">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading && responder.length < 1" class="text-center">
                <td colspan="5">Loading...</td>
              </tr>
              <tr v-if="!loading && responder.length < 1" class="text-center">
                <td colspan="5">No Record at the Moment</td>
              </tr>
              <tr v-else v-for="(x, index) in responder" :key="index">
                <td>{{ index + 1
                }}</td>
                <td class="text-left">{{ x.first_name + ' ' + x.last_name}}</td>
                <td class="text-left">{{ x.email }}</td>
                <td class="text-left">{{ x.phone }}</td>
                <td class="text-center table-actions">
                  <button class="btn btn-info btn-md mr-2" @click="handleResponderEdit(x)">Edit</button>
                  <!-- <button class="btn btn-primary mr-2" v-if="x.is_active == false" @click="activate(1, x.id)">Activate</button>
                  <button class="btn btn-danger mr-2" v-else @click="activate(0, x.id)">Deactivate</button> -->
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <v-row justify="space-around" v-if="responderDialog==true">
          <v-col cols="auto">
            <v-dialog
              transition="dialog-bottom-transition"
              v-model="responderDialog"
              width="40%"
              persistent
            >
                <v-card>
                  <v-toolbar
                    color="#007bff"
                    :title="edit ? 'Edit Responder': 'Add a New Responder'"
                  ></v-toolbar>
                  
                  <v-card-text>
                    <v-form v-model="form">
                      <v-text-field label="First Name" variant="outlined" v-model="formData.first_name" :rules="rules"></v-text-field>
                      <v-text-field label="Last Name" variant="outlined" v-model="formData.last_name" :rules="rules"></v-text-field>
                      <v-text-field label="Email" variant="outlined" v-model="formData.email" type="email" :rules="emailRules"></v-text-field>
                      <v-text-field label="Phone" variant="outlined" v-model="formData.phone" type="tel" :rules="PhoneRules"></v-text-field>
                    
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      variant="text"
                      @click="responderDialog=false;clearForm()"
                    >Close</v-btn>
                    <v-btn v-if="edit"
                      variant="tonal"
                      background="#f80089"
                      color="#f80089"
                      @click="editResponder"
                      :disabled="!form"
                    >Submit</v-btn>
                    <v-btn v-else
                      variant="tonal"
                      background="#f80089"
                      color="#f80089"
                      @click="createResponder"
                      :disabled="!form"
                    >Submit</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
          </v-col>
      
         
        </v-row>
        <v-row justify="space-around" v-if="notificationDialog == true">
          <v-col cols="auto">
            <v-dialog transition="dialog-bottom-transition" v-model="notificationDialog" width="40%" persistent>
              <v-card>
                <v-toolbar color="#007bff" title="Send Notification"></v-toolbar>
    
                <v-card-text>
                  <v-form v-model="form">
                    <v-text-field label="Title" variant="outlined" v-model="notificationData.title"
                      :rules="rules"></v-text-field>
                      <v-text-field label="Message" variant="outlined" v-model="notificationData.message"
                      :rules="rules"></v-text-field>
                    <!-- <v-select label="Select Responder" :item-title="'email'" :item-value="'id'" :items="responder" variant="outlined"
                      v-model="notificationData.responder_id" :rules="[requiredRules.required]"></v-select> -->
                  </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn variant="text" @click="notificationDialog = false;clearNotify()">Close</v-btn>
                  <v-btn variant="tonal" background="#f80089" color="#f80089"
                    :disabled="!form" @click="createNotification">Submit</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <div class="pagination mb-2">
      <div>
        <span>Page</span>
        <span>{{ currentPage }}</span>
        <span>of</span>
        <span>{{ pagination.length}}</span>
      </div>
      <div>
        <i disabled="currentPage === 1" @click="previousPage" style="cursor: pointer"><font-awesome-icon icon="chevron-left" /></i>
        <span>Previous</span>
        <div v-for="(n, i) in pagination" :key="i">
          <button class="btn" style="margin-right:0px" :class="{'btn-active': currentPage === n}" @click="changeCPage(n)">1</button>
        </div>
        <p v-if="pagination.length > 5" >...</p>
         <button class="btn " v-if="pagination.length > 5"
      :class="{ 'btn-inactive': currentPage === pagination.length }"
>{{ pagination.length }}</button>

        <span disabled="currentPage==pagination.length" @click="nextPage">Next</span>

        <i disabled="currentPage==pagination.length" @click="nextPage"><font-awesome-icon icon="chevron-right" style="cursor: pointer" /></i>
      </div>
    </div>
    </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import { makeGetRequest, makePostRequest, makePatchRequest} from '@/request';
import { toast } from 'vue3-toastify';

const swal = inject('$swal')
const form = ref(false)
const rules = ref([
  (v) => !!v || 'Field is required',
  (v) => (v && v.length >= 3) || 'Field length must be at least 3 characters',
])
const emailRules = ref([
             (v) => !!v || 'E-mail is required',
             (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
)
const PhoneRules = ref([
             (v) => !!v || 'Field is required',
             (v) => /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|909|908|911|912|913|914|915|916|917|919)([0-9]{7})$/.test(v) || 'Phone Number must be valid'
        ],
)
const requiredRules = ref({
  required: value => !!value || 'Field is required',
})
const responderDialog = ref(false)
const notificationDialog = ref(false)
const responderId = ref('')
const edit = ref(false)
const responder = ref([])
const search = ref('')
const loading = ref(false)
const formData = ref({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    identifier: 0
})
const notificationData = ref({
  message: '',
  title: ''
})
const clearNotify = () =>{
  notificationData.value.message = ''
  notificationData.value.title = ''
}
const clearForm = () => {
  formData.value.first_name = ''
  formData.value.last_name = ''
  formData.value.email = ''
  formData.value.phone = ''
}
const pagination = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const recordsPerPage = pageSize.value
const lastIndex = currentPage.value * recordsPerPage

const previousPage = () => {
  if (currentPage.value !== 1) {
    currentPage.value = currentPage.value - 1
    getResponder()
  }
}
const changeCPage = (page) => {
        currentPage.value = page
}
const nextPage = () => {
  if (currentPage.value !== lastIndex && currentPage.value < pagination.value.length) {
    currentPage.value = currentPage.value + 1
    getResponder()
  }
}
const preLoader = () => { 
      swal.fire({title: "", 
      customClass: {
          htmlContainer: 'custom-swal-popup'// Add your custom class here
      },
      html: '<div style="height:auto;width:50%;display:flex;align-items:center !important;justify-content:center !important"><img style="width: 100%;text-align:center" src="loader.gif" /></div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});
}
const getResponder = () => {
  preLoader()
  loading.value = true
    let request = {
        what: 'GetSecurityResponder',
        params: {
          search: ''
        }
    }
    if(search.value !== '') request.params.search = search.value.toLowerCase()
    makeGetRequest(request)
        .then(res => {
            responder.value = res.data.hos.results;
            pagination.value = res.data.hos.links.numbered
            loading.value = false
            swal.close()
        })
        .catch(error => {
          swal.close()
          loading.value = false
            toast.error(error)
        })
}
const createResponder = () =>{
  if (!form.value) return
  preLoader()
  responderDialog.value = false

  let request = {
    what: 'CreateSecurityResponder',
    data: formData.value
  }
  makePostRequest(request)
  .then(res => {
    if (res.status){
      toast.success(res.msg)
      clearForm()
      swal.close()
      getResponder()
    }
    else{
      toast.error(res.msg)
    }
  })
  .catch(error => {
    swal.close()
    toast.error(error)
  })

}
const createNotification = () => {
  if (!form.value) return
  preLoader()
  notificationDialog.value = false
  // let notifyArray = notificationData.value.campus_id
  // notificationData.value.campus_id = notifyArray.join(', ')
  let request = {
    what: 'CreateNotification',
    data: notificationData.value
  }
  makePostRequest(request)
    .then(res => {
      if (res.status) {
        toast.success(res.msg)
        clearNotify()
        swal.close()
        getResponder()
      }
      else {
        clearForm()
        toast.error(res.msg)
      }
    })
    .catch(error => {
      clearForm()
      swal.close()
      toast.error(error)
    })
}
const handleResponderEdit = (responder) =>{
  formData.value.first_name = responder.first_name
  formData.value.last_name = responder.last_name
  formData.value.email = responder.email
  formData.value.phone = responder.phone
  responderId.value = responder.id
  edit.value = true
  responderDialog.value = true
}
const editResponder = () => {
  if (!form.value) return
  preLoader()
  responderDialog.value = false
  let request = {
    what: 'EditSecurityResponder',
    data: formData.value,
    id: responderId.value
  }
  makePatchRequest(request)
  .then(res => {
    if (res.status){
      toast.success(res.msg)
      clearForm()
      swal.close()
      getResponder()
    }
    else{
      clearForm()
      toast.error(res.msg)
    }
  })
  .catch(error => {
    clearForm()
    swal.close()
    toast.error(error)
  })
}
const activate = (status, id) => {
  preLoader()
  let request = {
    what: 'ActivateResponder',
    id: id,
    data: {
      active: status
    }
  }
  makePatchRequest(request)
    .then((res) => {
      if (res.status == true) {
            toast.success(res.msg)
            getResponder()
            swal.close()
      }
      else {
        swal.close()
        toast.error(res.msg)
      }
    })
    .catch((error) => {
      swal.close()
      toast.error(error)
    })
}
onMounted(() => {
  getResponder()
})
</script>
<script>
export default {
    name: 'ResponderPage',
}
</script>
<style scoped>

nav{
  background: transparent !important;
}
.pagination{
  width: 97%;
  margin: 0 auto;
  display: flex; 
  align-items: center; 
  justify-content:space-between;
}
.pagination div{
  display: flex;
  align-items: center; 
  gap: 10px;
  
}
.pagination div span, .pagination div i{
  font-size: 14px;
}
@media screen and (max-width: 720px){
  .pagination{
    flex-direction: column;
    gap: 15px;
  }
}
</style>